/* Container and Title */
.container {
  max-width: 1300px;
  margin: 2rem auto;
  padding: 0 1rem;
  padding-left: 94px;
}

.title {
  color: #248061;
  font-size: 1.8rem;
  font-weight: 600;
  margin-bottom: 2rem;
  text-align: center;
}

.accessCodeInput {
  background-color: #fff;
  color: #248061;
  border: 2px solid #248061;
  width: 185px;
  border-radius: 20px;
}

/* Search and Filters */
.searchAndFilters {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 24px;
}

.searchInput {
  padding: 12px;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  font-size: 1rem;
  width: 100%;
  background-color: #f8f9fa;
  max-width: 450px;
  margin: auto;
}

.searchInput:focus {
  outline: none;
  border-color: #248061;
  box-shadow: 0 0 0 2px rgba(36, 128, 97, 0.1);
}

/* Desktop Table Styles */
.tableContainer {
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin: 0 -1rem;
}

.doublesTable {
  width: 100%;
  border-collapse: collapse;
  min-width: auto;
}

.doublesTable th,
.doublesTable td {
  padding: 0.75rem;
  text-align: center;
}

.doublesTable th {
  background-color: #f8f9fa;
  color: #495057;
  font-weight: 600;
  border-bottom: 2px solid #dee2e6;
  position: relative;
  white-space: nowrap;
}

.doublesTable td {
  border-bottom: 1px solid #dee2e6;
  color: #495057;
}

/* Column Widths */
.doublesTable th:nth-child(1),
.doublesTable td:nth-child(1) {
  width: 5%;
}

.nameCell {
  min-width: 150px;
  width: 20%;
}

.doublesTable th:nth-child(3),
.doublesTable td:nth-child(3) {
  width: 12%;
}

.doublesTable th:nth-child(4),
.doublesTable td:nth-child(4) {
  width: 12%;
}

.doublesTable th:nth-child(5),
.doublesTable td:nth-child(5) {
  width: 10%;
}

.doublesTable th:nth-child(6),
.doublesTable td:nth-child(6) {
  width: 15%;
}

/* Sortable Headers */
.sortableHeader {
  cursor: pointer;
  user-select: none;
  transition: background-color 0.2s ease;
  padding-right: 2rem !important;
}

.sortableHeader:hover {
  background-color: #e9ecef;
}

/* Name Cell Styles */
.nameContainer {
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: center;
}

.doubleImage {
  width: 25px;
  height: 25px;
  object-fit: contain;
  border-radius: 4px;
}

.doubleName {
  font-weight: 500;
  color: #333;
}

/* Mobile Card Styles */
.mobileView {
  display: none;
}

.mobileCard {
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 16px;
  overflow: hidden;
}

.mobileCardHeader {
  background-color: #f8f9fa;
  padding: 16px;
  display: flex;
  align-items: center;
  gap: 12px;
  border-bottom: 1px solid #dee2e6;
}

.mobileId {
  background-color: #248061;
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.9rem;
}

.mobileName {
  font-size: 1.1rem;
  color: #248061;
  margin: 0;
  font-weight: 600;
}

.mobileCardBody {
  padding: 16px;
}

.mobileRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  border-bottom: 1px solid #f1f1f1;
}

.mobileRow:last-child {
  border-bottom: none;
}

.mobileLabel {
  color: #6c757d;
  font-size: 0.9rem;
}

.mobileValue {
  font-weight: 500;
  color: #495057;
}

.mobileActions {
  padding: 16px;
  display: flex;
  gap: 8px;
  background-color: #f8f9fa;
  border-top: 1px solid #dee2e6;
}

/* Button Styles */
.seeDoubleButton,
.joinDoubleButton {
  padding: 10px 20px;
  border-radius: 20px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 100%;
}

.seeDoubleButton {
  background-color: #fff;
  color: #248061;
  border: 2px solid #248061;
  margin-bottom: 8px;
}

.joinDoubleButton {
  background-color: #248061;
  color: #fff;
  border: 2px solid #248061;
}

.seeDoubleButton:hover {
  background-color: rgba(36, 128, 97, 0.1);
}

.joinDoubleButton:hover {
  background-color: #1a6047;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(36, 128, 97, 0.2);
}

.redText {
  color: #dc3545;
  font-size: 0.9rem;
  margin-top: 8px;
  text-align: center;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .desktopView {
    display: none;
  }
  .mobileView {
    display: block;
  }

  .container {
    padding: 16px;
  }

  .searchAndFilters {
    padding: 0 16px;
  }

  .seeDoubleButton {
    margin-bottom: 0px;
  }

  .accessCodeInput {
    margin: auto;
    width: 100%;
  }
}

@media (max-width: 480px) {
  .container {
    padding: 8px;
    margin: 0.5rem;
  }

  .mobileCardHeader,
  .mobileCardBody,
  .mobileActions {
    padding: 12px;
  }

  .mobileName {
    font-size: 1rem;
  }

  .mobileLabel,
  .mobileValue {
    font-size: 0.85rem;
  }

  .seeDoubleButton,
  .joinDoubleButton {
    padding: 8px;
    font-size: 0.85rem;
  }
}

/* Add these styles to your existing CSS module */
.loadingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 400px;
  width: 100%;
}

.loadingSpinner {
  width: 50px;
  height: 50px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #248061;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 1rem;
}

.noDoublesContainer {
  text-align: center;
  padding: 2rem;
  background-color: #f8f9fa;
  border-radius: 8px;
  margin: 2rem auto;
  max-width: 600px;
}

.createDoubleButton {
  background-color: #248061;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  margin-top: 1rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.createDoubleButton:hover {
  background-color: #1a6047;
  transform: translateY(-2px);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
