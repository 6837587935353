.container {
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  padding: 24px;
  max-width: 1200px;
  margin: 24px auto;
  transition: all 0.3s ease;
}

.title {
  color: #248061;
  font-size: 1.8rem;
  font-weight: 600;
  margin-bottom: 24px;
  text-align: center;
  border-bottom: 2px solid #248061;
  padding-bottom: 8px;
}

.coinsInfo {
  background-color: #f8f9fa;
  padding: 16px;
  border-radius: 8px;
  text-align: center;
  margin-bottom: 24px;
  border: 1px solid #e9ecef;
}

.coinsAmount {
  color: #248061;
  font-weight: bold;
  font-size: 1.2rem;
}

.ticketContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  padding: 20px 0;
}

.ticket {
  background-color: #f8f9fa;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  transition: transform 0.2s ease;
}

.ticket:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.ticketHeader {
  font-size: 1.1rem;
  font-weight: 600;
  color: #248061;
  margin-bottom: 12px;
}

.ticketInfo {
  margin: 8px 0;
  color: #495057;
  font-size: 0.95rem;
}

.ticketLabel {
  color: #6c757d;
  font-size: 0.9rem;
}

.ticketValue {
  font-weight: 600;
  color: #212529;
}

/* Status-specific styles */
.ticket-open {
  border-left: 4px solid #248061;
}

.ticket-won {
  border-left: 4px solid #28a745;
}

.ticket-lost {
  border-left: 4px solid #dc3545;
}

.ticket-cancelled {
  border-left: 4px solid #6c757d;
}

.ticket-pending {
  border-left: 4px solid #ffc107;
}

.status {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.85rem;
  font-weight: 600;
  text-transform: uppercase;
}

.status-open {
  background-color: rgba(36, 128, 97, 0.1);
  color: #248061;
}

.status-won {
  background-color: rgba(40, 167, 69, 0.1);
  color: #28a745;
}

.status-lost {
  background-color: rgba(220, 53, 69, 0.1);
  color: #dc3545;
}

.status-cancelled {
  background-color: rgba(108, 117, 125, 0.1);
  color: #6c757d;
}

.status-pending {
  background-color: rgba(255, 193, 7, 0.1);
  color: #ffc107;
}

.error {
  text-align: center;
  color: #dc3545;
  padding: 20px;
  background-color: rgba(220, 53, 69, 0.1);
  border-radius: 8px;
  margin: 20px auto;
  max-width: 600px;
}

@media (max-width: 768px) {
  .container {
    margin: 16px;
    padding: 16px;
  }

  .title {
    font-size: 1.5rem;
  }

  .ticketContainer {
    grid-template-columns: 1fr;
  }
}

.combinationLabel {
  background-color: #248061;
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.9rem;
  margin-right: 8px;
}

.combinationBetItem {
  padding: 12px;
  border-bottom: 1px solid #eee;
}

.combinationBetItem:last-child {
  border-bottom: none;
}

.ticketFooter {
  margin-top: 12px;
  padding-top: 12px;
  border-top: 1px solid #eee;
}
