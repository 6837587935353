.matchesContainer {
  display: flex;
  flex-direction: column;
}

.matchList,
.matchDetails {
  width: 100%;
  display: flex;
  justify-content: center;
}

@media (min-width: 768px) {
  .matchesContainer {
    flex-direction: row;
  }

  .matchList,
  .matchDetails {
    width: 100%;
  }
}

.hidden {
  display: none;
}

.noMatchesContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  text-align: center;
  min-height: 50vh;
}

.backButton {
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}

.backButton:hover {
  background-color: #0056b3;
}
