.container {
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  padding: 24px;
  max-width: 1200px;
  margin: 24px auto;
  transition: all 0.3s ease;
}

.content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
}

.leagueInfo {
  background-color: #f8f9fa;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.leagueTitle {
  color: #248061;
  font-size: 1.8rem;
  font-weight: 600;
  margin-bottom: 24px;
  text-align: center;
  border-bottom: 2px solid #248061;
  padding-bottom: 8px;
}

.infoCard {
  background-color: #fff;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.infoRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  padding: 8px 0;
  border-bottom: 1px solid #e9ecef;
}

.infoLabel {
  color: #6c757d;
  font-size: 0.95rem;
}

.infoValue {
  color: #248061;
  font-weight: 600;
  font-size: 1rem;
}

.joinButton {
  width: 100%;
  padding: 12px;
  background-color: #248061;
  color: white;
  border: none;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 16px;
}

.joinButton:hover {
  background-color: #1a6047;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.joinButton:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
  transform: none;
}

.alert {
  background-color: #dc3545;
  color: white;
  padding: 12px;
  border-radius: 8px;
  text-align: center;
  margin: 16px 0;
  font-weight: 500;
}

.loading {
  text-align: center;
  padding: 40px;
  color: #6c757d;
}

@media (max-width: 768px) {
  .container {
    margin: 16px;
    padding: 16px;
  }

  .content {
    grid-template-columns: 1fr;
  }

  .leagueTitle {
    font-size: 1.5rem;
  }

  .infoCard {
    padding: 12px;
  }
}
