.container {
  max-width: 1200px;
  margin: 2rem auto;
  padding: 0 1rem;
}

.title {
  color: #248061;
  font-size: 1.8rem;
  font-weight: 600;
  margin-bottom: 2rem;
  text-align: center;
}

.tableContainer {
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: auto;
  margin: 0 -1rem;
}

.doublesTable {
  width: 100%;
  border-collapse: collapse;
  min-width: 800px;
}

.doublesTable th {
  background-color: #f8f9fa;
  color: #495057;
  font-weight: 600;
  padding: 1rem;
  text-align: center;
  border-bottom: 2px solid #dee2e6;
  position: relative;
  white-space: nowrap;
}

.doublesTable td {
  padding: 1rem;
  border-bottom: 1px solid #dee2e6;
  color: #495057;
}

.doubleRow:hover {
  background-color: #f8f9fa;
}

.sortableHeader {
  cursor: pointer;
  user-select: none;
  transition: background-color 0.2s ease;
  padding-right: 2rem !important;
}

.sortableHeader:hover {
  background-color: #e9ecef;
}

.sortableHeader::after {
  content: attr(data-icon);
  position: absolute;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
}

.nameCell {
  min-width: 200px;
}

.nameContainer {
  display: flex;
  align-items: center;
  gap: 12px;
}

.doubleImage {
  width: 30px;
  height: 30px;
  object-fit: contain;
  border-radius: 4px;
}

.doubleName {
  font-weight: 500;
  color: #333;
}

.actions {
  display: flex;
  gap: 0.5rem;
  white-space: nowrap;
}

.viewButton,
.betButton {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.viewButton {
  background-color: #e9ecef;
  color: #495057;
}

.betButton {
  background-color: #248061;
  color: white;
}

.viewButton:hover {
  background-color: #dee2e6;
}

.betButton:hover {
  background-color: #1a6047;
}

/* Mobile Card Styles */
.mobileView {
  display: none;
}

.mobileCard {
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 16px;
  overflow: hidden;
}

.mobileCardHeader {
  background-color: #f8f9fa;
  padding: 16px;
  display: flex;
  align-items: center;
  gap: 12px;
  border-bottom: 1px solid #dee2e6;
}

.mobileId {
  background-color: #248061;
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.9rem;
}

.mobileName {
  font-size: 1.1rem;
  color: #248061;
  margin: 0;
  font-weight: 600;
}

.mobileCardBody {
  padding: 16px;
}

.mobileRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  border-bottom: 1px solid #f1f1f1;
}

.mobileRow:last-child {
  border-bottom: none;
}

.mobileLabel {
  color: #6c757d;
  font-size: 0.9rem;
}

.mobileValue {
  font-weight: 500;
  color: #495057;
}

.mobileActions {
  padding: 16px;
  display: flex;
  gap: 8px;
  background-color: #f8f9fa;
  border-top: 1px solid #dee2e6;
}

/* Empty State */
.emptyState {
  text-align: center;
  padding: 3rem;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.emptyState p {
  color: #495057;
  margin-bottom: 1rem;
}

.emptyState button {
  padding: 0.75rem 1.5rem;
  background-color: #248061;
  color: white;
  border: none;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.emptyState button:hover {
  background-color: #1a6047;
}

/* Loading State */
.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  gap: 16px;
}

.loadingSpinner {
  width: 40px;
  height: 40px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #248061;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Responsive Design */
@media (max-width: 1024px) {
  .container {
    margin: 1rem;
  }

  .tableContainer {
    margin: 0;
    border-radius: 8px;
  }
}

@media (max-width: 768px) {
  .desktopView {
    display: none;
  }

  .mobileView {
    display: block;
  }

  .container {
    padding: 12px;
  }

  .title {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .mobileActions {
    flex-direction: row;
  }

  .viewButton,
  .betButton {
    flex: 1;
    padding: 10px;
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .container {
    padding: 8px;
    margin: 0.5rem;
  }

  .title {
    font-size: 1.3rem;
    margin-bottom: 1rem;
  }

  .mobileCardHeader {
    padding: 12px;
  }

  .mobileName {
    font-size: 1rem;
  }

  .mobileCardBody {
    padding: 12px;
  }

  .mobileLabel,
  .mobileValue {
    font-size: 0.85rem;
  }

  .mobileActions {
    padding: 12px;
  }

  .viewButton,
  .betButton {
    padding: 8px;
    font-size: 0.85rem;
  }
}
