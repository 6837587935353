.classificationTable {
  composes: table from global;
}

.classificationTable > :not(caption) > * > * {
  background-color: transparent !important;
}

.firstPlace {
  background-color: #90ee90 !important; /* Light green */
}

.secondPlace {
  background-color: #ffa500 !important; /* Orange */
}

.thirdPlace {
  background-color: #ffff00 !important; /* Yellow */
}

.winner {
  background-color: #90ee90 !important; /* Light green */
}
