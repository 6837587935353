.container {
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
  padding: 24px;
  max-width: 500px;
  width: 95%;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  max-height: 85vh;
  position: relative;
  animation: slideUp 0.3s ease-out;
}

/* Bet Type Toggle */
.betTypeToggle {
  display: flex;
  gap: 8px;
  margin-bottom: 20px;
  background: #f5f5f5;
  padding: 4px;
  border-radius: 12px;
}

.betTypeToggle button {
  flex: 1;
  padding: 12px;
  border: none;
  border-radius: 8px;
  font-weight: 600;
  font-size: 0.95rem;
  cursor: pointer;
  transition: all 0.2s ease;
  background: transparent;
  color: #666;
}

.betTypeToggle button.active {
  background: #248061;
  color: white;
  box-shadow: 0 2px 8px rgba(36, 128, 97, 0.2);
}

/* Bet Containers */
.singleBetsContainer,
.combinationBetContainer {
  flex: 1;
  overflow-y: auto;
  padding-right: 12px;
  margin: 16px 0;
  scrollbar-width: thin;
  scrollbar-color: #248061 #f5f5f5;
}

.singleBetsContainer::-webkit-scrollbar,
.combinationBetContainer::-webkit-scrollbar {
  width: 6px;
}

.singleBetsContainer::-webkit-scrollbar-track,
.combinationBetContainer::-webkit-scrollbar-track {
  background: #f5f5f5;
  border-radius: 10px;
}

.singleBetsContainer::-webkit-scrollbar-thumb,
.combinationBetContainer::-webkit-scrollbar-thumb {
  background: #248061;
  border-radius: 10px;
}

/* Bet Items */
.selectedBet,
.singleBetItem {
  background: #f8f9fa;
  border-radius: 12px;
  padding: 16px;
  margin-bottom: 12px;
  position: relative;
  border: 1px solid #e9ecef;
  transition:
    transform 0.2s ease,
    box-shadow 0.2s ease;
}

.selectedBet:hover,
.singleBetItem:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.betInfo {
  margin-right: 32px;
}

.betInfo p {
  margin: 4px 0;
  color: #495057;
}

.betInfo h4 {
  color: #248061;
  margin: 8px 0;
  font-size: 1.1rem;
}

/* Remove Bet Button */
.removeBet {
  position: absolute;
  top: 12px;
  right: 12px;
  background: none;
  border: none;
  color: #dc3545;
  font-size: 1.2rem;
  cursor: pointer;
  padding: 4px;
  border-radius: 50%;
  transition: all 0.2s ease;
}

.removeBet:hover {
  background: rgba(220, 53, 69, 0.1);
  transform: scale(1.1);
}

/* Bet Amount Input */
.betAmountInput {
  background: rgba(36, 128, 97, 0.05);
  border-radius: 12px;
  padding: 16px;
  margin: 12px 0;
  display: flex;
  align-items: center;
  gap: 12px;
}

.betAmountInput p {
  color: #248061;
  font-weight: 500;
  margin: 0;
  min-width: 80px;
}

.betAmountInput input {
  flex: 1;
  padding: 10px;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  font-size: 1rem;
  transition: all 0.2s ease;
}

.betAmountInput input:focus {
  outline: none;
  border-color: #248061;
  box-shadow: 0 0 0 3px rgba(36, 128, 97, 0.1);
}

/* Potential Winnings */
.potentialWinnings {
  background: rgba(36, 128, 97, 0.05);
  border-radius: 12px;
  padding: 16px;
  color: #248061;
  font-weight: 600;
  text-align: center;
  font-size: 1.1rem;
}

/* Total Odds Display */
.totalOdds {
  background: #f8f9fa;
  border-radius: 12px;
  padding: 16px;
  text-align: center;
  margin: 12px 0;
}

.totalOdds p {
  color: #666;
  margin: 0 0 4px 0;
}

.totalOdds span {
  color: #248061;
  font-size: 1.2rem;
  font-weight: 600;
}

/* Bet Button */
.betButtonWrapper {
  margin-top: auto;
  padding-top: 16px;
}

.betButtonFull {
  width: 100%;
  padding: 16px;
  background: #248061;
  color: white;
  border: none;
  border-radius: 12px;
  font-weight: 600;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.betButtonFull:hover {
  background: #1a6047;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(36, 128, 97, 0.2);
}

.betButtonFull:active {
  transform: translateY(0);
}

/* Error Messages */
.error,
.duplicateWarning {
  background: #fff3cd;
  color: #856404;
  border-radius: 12px;
  padding: 12px 16px;
  margin: 12px 0;
  text-align: center;
  animation: fadeIn 0.3s ease-out;
  font-size: 0.9rem;
}

.error {
  background: #f8d7da;
  color: #721c24;
}

/* Animations */
@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .container {
    padding: 20px;
    margin: 16px auto;
    max-height: 90vh;
  }

  .betTypeToggle button {
    padding: 10px;
    font-size: 0.9rem;
  }

  .betInfo h4 {
    font-size: 1rem;
  }

  .betAmountInput {
    padding: 12px;
  }

  .betButtonFull {
    padding: 14px;
  }
}

@media (max-width: 480px) {
  .container {
    padding: 16px;
    margin: 12px auto;
    width: 92%;
  }

  .betTypeToggle {
    padding: 3px;
  }

  .betTypeToggle button {
    padding: 8px;
    font-size: 0.85rem;
  }

  .selectedBet,
  .singleBetItem {
    padding: 12px;
  }

  .betInfo h4 {
    font-size: 0.95rem;
  }

  .betInfo p {
    font-size: 0.9rem;
  }

  .betAmountInput {
    padding: 10px;
    flex-direction: column;
    align-items: stretch;
    gap: 8px;
  }

  .betAmountInput p {
    font-size: 0.9rem;
  }

  .betAmountInput input {
    width: 100%;
    padding: 8px;
    font-size: 0.9rem;
  }

  .potentialWinnings {
    font-size: 1rem;
    padding: 12px;
  }

  .betButtonFull {
    padding: 12px;
    font-size: 0.95rem;
  }

  /* Remove the hover effect for mobile */
  .removeBet:hover {
    background: none;
    transform: none;
  }
}

/* Support for tall mobile screens */
@media (max-height: 700px) {
  .container {
    max-height: 95vh;
  }

  .singleBetsContainer,
  .combinationBetContainer {
    max-height: 50vh;
  }
}
