/* Container and Title */
.container {
  max-width: 1300px;
  margin: 2rem auto;
  padding: 0 1rem;
  padding-left: 94px;
}

.title {
  color: #248061;
  font-size: 1.8rem;
  font-weight: 600;
  margin-bottom: 2rem;
  text-align: center;
}

.accessCodeInput {
  background-color: #fff;
  color: #248061;
  border: 2px solid #248061;
  width: 185px;
  border-radius: 20px;
}

/* Search and Filters */
.searchAndFilters {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 24px;
}

.searchInput {
  padding: 12px;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  font-size: 1rem;
  width: 100%;
  background-color: #f8f9fa;
  max-width: 450px;
  margin: auto;
}

.searchInput:focus {
  outline: none;
  border-color: #248061;
  box-shadow: 0 0 0 2px rgba(36, 128, 97, 0.1);
}

/* Desktop Table Styles */
.tableContainer {
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin: 0 -1rem;
  width: 100%;
  margin: 0 auto;
  overflow-x: auto;
}

.leaguesTable {
  width: 100%;
  border-collapse: collapse;
  min-width: 800px;
}

.leaguesTable th,
.leaguesTable td {
  padding: 0.75rem 0.5rem;
  text-align: left;
}

.leaguesTable th {
  background-color: #f8f9fa;
  color: #495057;
  font-weight: 600;
  border-bottom: 2px solid #dee2e6;
  position: relative;
  white-space: nowrap;
}

.leaguesTable td {
  border-bottom: 1px solid #dee2e6;
  color: #495057;
}

/* Column Widths */
.leaguesTable th:nth-child(1),
.leaguesTable td:nth-child(1) {
  width: 10%;
}

.nameCell {
  width: 22%;
  min-width: 150px;
}

.leaguesTable th:nth-child(2),
.leaguesTable td:nth-child(2) {
  width: 12%;
  min-width: 80px;
}

.leaguesTable th:nth-child(3),
.leaguesTable td:nth-child(3) {
  width: 12%;
  min-width: 80px;
}

.leaguesTable th:nth-child(4),
.leaguesTable td:nth-child(4) {
  width: 12%;
  min-width: 80px;
}

.leaguesTable th:nth-child(5),
.leaguesTable td:nth-child(5) {
  width: 15%;
  min-width: 100px;
}

.leaguesTable th:nth-child(6),
.leaguesTable td:nth-child(6) {
  width: 12%;
  min-width: 90px;
}

.leaguesTable th:nth-child(7),
.leaguesTable td:nth-child(7) {
  width: 15%;
  min-width: 120px;
}

.leaguesTable th:nth-child(8),
.leaguesTable td:nth-child(8) {
  width: 15%;
}

/* Sortable Headers */
.sortableHeader {
  cursor: pointer;
  user-select: none;
  transition: background-color 0.2s ease;
  padding-right: 2rem !important;
}

.sortableHeader:hover {
  background-color: #e9ecef;
}

/* Name Cell Styles */
.nameContainer {
  display: flex;
  align-items: center;
  gap: 12px;
}

.leagueImage {
  width: 25px;
  height: 25px;
  object-fit: contain;
  border-radius: 4px;
}

.leagueName {
  font-weight: 500;
  color: #333;
}

/* Mobile Card Styles */
.mobileView {
  display: none;
}

.mobileCard {
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 16px;
  overflow: hidden;
}

.mobileCardHeader {
  background-color: #f8f9fa;
  padding: 16px;
  display: flex;
  align-items: center;
  gap: 12px;
  border-bottom: 1px solid #dee2e6;
}

.mobileId {
  background-color: #248061;
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.9rem;
}

.mobileName {
  font-size: 1.1rem;
  color: #248061;
  margin: 0;
  font-weight: 600;
}

.mobileCardBody {
  padding: 16px;
}

.mobileRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  border-bottom: 1px solid #f1f1f1;
}

.mobileRow:last-child {
  border-bottom: none;
}

.mobileLabel {
  color: #6c757d;
  font-size: 0.9rem;
}

.mobileValue {
  font-weight: 500;
  color: #495057;
}

.mobileActions {
  padding: 16px;
  display: flex;
  gap: 8px;
  background-color: #f8f9fa;
  border-top: 1px solid #dee2e6;
}

/* Button Styles */
.seeLeagueButton,
.joinLeagueButton {
  padding: 10px 20px;
  border-radius: 20px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 100%;
}

.seeLeagueButton {
  background-color: #fff;
  color: #248061;
  border: 2px solid #248061;
  margin-bottom: 8px;
}

.joinLeagueButton {
  background-color: #248061;
  color: #fff;
  border: 2px solid #248061;
}

.seeLeagueButton:hover {
  background-color: rgba(36, 128, 97, 0.1);
}

.joinLeagueButton:hover {
  background-color: #1a6047;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(36, 128, 97, 0.2);
}

/* Responsive Design */
@media (max-width: 1024px) {
  .desktopView {
    display: none;
  }
  .mobileView {
    display: block;
  }

  .container {
    padding: 16px;
  }

  .searchAndFilters {
    padding: 0 16px;
  }

  .seeLeagueButton {
    margin-bottom: 0px;
  }

  .accessCodeInput {
    margin: auto;
    width: 100%;
  }
}

@media (max-width: 480px) {
  .container {
    padding: 8px;
    margin: 0.5rem;
  }

  .mobileCardHeader,
  .mobileCardBody,
  .mobileActions {
    padding: 12px;
  }

  .mobileName {
    font-size: 1rem;
  }

  .mobileLabel,
  .mobileValue {
    font-size: 0.85rem;
  }

  .seeLeagueButton,
  .joinLeagueButton {
    padding: 8px;
    font-size: 0.85rem;
  }
}

.redText {
  color: #dc3545;
  font-size: 0.9rem;
  margin-top: 8px;
  text-align: center;
}
