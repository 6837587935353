.aboutUsContainer {
  background-color: #fff;
  min-height: 100vh;
  font-family: 'Inter', sans-serif;
}

.content {
  max-width: 1800px;
  margin: 0 auto;
  margin-left: 79px;
  padding: 2rem 2rem;
}

.title {
  font-size: clamp(2.5rem, 4vw, 3.5rem);
  color: #333;
  text-align: center;
  margin-bottom: 3rem;
  font-weight: 600;
  letter-spacing: -0.02em;
}

.description {
  font-size: clamp(1rem, 2vw, 1.2rem);
  line-height: 1.6;
  color: #666;
  margin-bottom: 2rem;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
}

.imageGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 3rem;
  margin: 4rem 0;
  padding: 0 2rem;
}

.imageItem {
  position: relative;
  height: 400px;
  border-radius: 20px;
  overflow: hidden;
  transition: all 0.3s ease;
  background-color: #fff;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
}

.imageItem:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 40px rgba(0, 0, 0, 0.1);
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.imageItem:hover .image {
  transform: scale(1.05);
}

.imageItem p {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(10px);
  margin: 0;
  font-size: 1.2rem;
  font-weight: 500;
  color: #333;
  transition: all 0.3s ease;
}

.imageItem:hover p {
  background: rgba(255, 255, 255, 0.98);
}

.contactDescription {
  font-size: clamp(1.2rem, 2.5vw, 1.5rem);
  color: #333;
  text-align: center;
  margin: 4rem 0 0rem;
  font-weight: 600;
}

.contactForm {
  max-width: 600px;
  margin: 0 auto;
  padding: 2rem;
}

.formTextarea {
  width: 100%;
  min-height: 150px;
  padding: 1rem;
  border: 2px solid #e0e0e0;
  border-radius: 12px;
  font-size: 1rem;
  transition: all 0.3s ease;
  background: white;
  resize: vertical;
  margin-bottom: 1.5rem;
}

.formTextarea:focus {
  border-color: #248061;
  outline: none;
}

.submitButton {
  width: 100%;
  padding: 1rem 2rem;
  font-size: 1rem;
  border: none;
  border-radius: 50px;
  background: #248061;
  color: #ffffff;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.submitButton:hover {
  transform: translateY(-2px);
  background: #248061;
}

.submitButton:disabled {
  background-color: #e0e0e0;
  cursor: not-allowed;
  transform: none;
}

.successMessage {
  color: #10b981;
  text-align: center;
  margin-top: 1rem;
  padding: 1rem;
  border-radius: 12px;
  background-color: rgba(16, 185, 129, 0.1);
}

.errorMessage {
  color: #ef4444;
  text-align: center;
  margin-top: 1rem;
  padding: 1rem;
  border-radius: 12px;
  background-color: rgba(239, 68, 68, 0.1);
}

/* Responsive Design */
@media (max-width: 1200px) {
  .imageGrid {
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
  }

  .imageItem {
    height: 350px;
  }
}

@media (max-width: 768px) {
  .content {
    padding: 3rem 1.5rem;
  }

  .imageGrid {
    grid-template-columns: 1fr;
    padding: 0 1rem;
  }

  .imageItem {
    height: 300px;
  }

  .contactForm {
    padding: 1.5rem;
  }
}

@media (max-width: 480px) {
  .content {
    padding: 2rem 1rem;
  }

  .imageItem {
    height: 250px;
  }

  .imageItem p {
    padding: 1.5rem;
    font-size: 1.1rem;
  }

  .submitButton {
    padding: 0.8rem 1.5rem;
  }
}
