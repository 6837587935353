.languageSwitcher {
  position: relative;
  display: inline-block;
}

.languageButton {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  transition: transform 0.3s ease;
}

.languageButton:hover {
  transform: scale(1.1);
}

.flag {
  width: 30px;
  height: 20px;
  object-fit: cover;
}

.optionsContainer {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: rgba(255, 255, 255, 0);
  /* border: 1px solid #ddd; */
  border-radius: 4px;
  padding: 10px 0px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  z-index: 1000;
}

.optionsContainer .languageButton {
  display: block;
}
