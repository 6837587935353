.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 5dvh 5vw;
}

.title {
  font-size: clamp(1.5rem, 5vw, 2rem);
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

.form {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.inputGroup {
  margin-bottom: 15px;
}

.inputGroup label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: clamp(0.9rem, 2.5vw, 1rem);
}

.inputGroup input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: clamp(0.9rem, 2.5vw, 1rem);
}

.userList {
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 15px;
}

.userItem {
  margin-bottom: 5px;
  font-size: clamp(0.9rem, 2.5vw, 1rem);
}

.submitButton {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  font-size: clamp(0.9rem, 2.5vw, 1rem);
  width: 100%;
}

.submitButton:hover {
  background-color: #0056b3;
}

.message {
  margin-top: 15px;
  padding: 10px;
  background-color: #e9ecef;
  border-radius: 4px;
  text-align: center;
  font-size: clamp(0.9rem, 2.5vw, 1rem);
}

.backButton {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s;
  font-size: clamp(0.9rem, 2.5vw, 1rem);
}

.backButton:hover {
  background-color: #0056b3;
}

@media (max-width: 600px) {
  .form {
    padding: 15px;
  }
}
