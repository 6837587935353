.container {
  padding: 5dvh 5vw;
  max-width: 1200px;
  margin: 0 auto;
}

.title {
  font-size: clamp(1.5rem, 5vw, 2rem);
  margin-bottom: 20px;
  text-align: center;
}

.loading,
.error,
.noWithdrawals {
  text-align: center;
  font-size: clamp(1rem, 3vw, 1.2rem);
  margin-top: 20px;
}

.withdrawalsTable {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  font-size: clamp(0.8rem, 2.5vw, 1rem);
}

.withdrawalsTable th,
.withdrawalsTable td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: left;
}

.withdrawalsTable th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.withdrawalsTable tr:nth-child(even) {
  background-color: #f9f9f9;
}

.approveButton,
.rejectButton {
  padding: 8px 12px;
  margin: 0 5px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: clamp(0.8rem, 2vw, 1rem);
}

.approveButton {
  background-color: #4caf50;
  color: white;
}

.approveButton:hover {
  background-color: #45a049;
}

.rejectButton {
  background-color: #f44336;
  color: white;
}

.rejectButton:hover {
  background-color: #da190b;
}

.backButton {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s;
  font-size: clamp(0.9rem, 2.5vw, 1rem);
}

.backButton:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .withdrawalsTable {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }
}
