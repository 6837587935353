.cardModes {
  position: relative;
  padding-top: 80px;
  width: 45%;
  min-width: 300px;
  overflow: hidden;
  border-radius: 8px;
  color: #fff;
}

.cardGame {
  position: relative;
  padding-top: 80px;
  min-width: 300px;
  overflow: hidden;
  border-radius: 8px;
  color: #fff;
}

.cardImage {
  width: 100%;
  height: auto;
  display: block;
}

.cardContent {
  position: absolute;
  bottom: 20px;
  left: 20px;
  right: 20px;
  text-align: left;
}

.cardWrapperModes {
  display: flex;
  align-items: flex-end;
  gap: 20px;
}

.cardWrapperGame {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cardContent h2 {
  font-size: 1.8em;
  margin-bottom: 10px;
}

.cardContentLeftGame {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 8px;
  text-align: center;
  gap: 5px;
}

.cardContentLeftModes {
  display: flex;
  flex-direction: column;
  padding-top: 8px;
}

.cardContentLeftGame h2 {
  text-align: center;
  font-weight: bold;
  color: #248061;
}

.cardContentLeftModes h2 {
  font-weight: bold;
  color: #248061;
}

.cardContent p {
  margin-bottom: 0;
}

.cardContent button {
  background-color: #248061;
  color: #000;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

.cardContent button:hover {
  background-color: #248061;
}

@media (max-width: 768px) {
  .cardContent {
    position: static;
    display: flex;
  }

  .cardModes {
    color: #000;
    border: 2px solid;
    padding: 10px;
  }

  .cardGame {
    color: #000;
    border: 2px solid;
    padding: 10px;
  }

  .cardContent h2 {
    text-align: center;
  }

  .cardWrapperModes {
    flex-direction: column; /* Ajusta el tamaño de la fuente para pantallas pequeñas */
    align-items: center;
  }

  .cardWrapperGame {
    flex-direction: column; /* Ajusta el tamaño de la fuente para pantallas pequeñas */
    align-items: center;
  }
}

@media (max-width: 480px) {
  .cardWrapperModes {
    gap: 0px;
  }
}
