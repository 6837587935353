.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100dvh;
  padding: 5dvh 5vw;
  background-color: #f0f2f5;
}

.title {
  font-size: clamp(2rem, 5vw, 2.5rem);
  color: #333;
  margin-bottom: 2rem;
  text-align: center;
}

.buttonContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
}

.button {
  display: inline-block;
  padding: 0.8rem 1.5rem;
  font-size: clamp(0.9rem, 2.5vw, 1rem);
  text-decoration: none;
  color: #fff;
  background-color: #007bff;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  text-align: center;
  min-width: 200px;
}

.button:hover {
  background-color: #0056b3;
}

@media (max-width: 600px) {
  .buttonContainer {
    flex-direction: column;
    width: 100%;
  }

  .button {
    width: 100%;
  }
}
