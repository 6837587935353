.matchList {
  width: 60%;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
}

.matchCard {
  background-color: #f8f9fa;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 16px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  transition: transform 0.2s ease;
}

.matchCard:hover {
  transform: translateY(-2px);
}

.cardBody {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.teamsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
}

.teamName {
  font-size: 1rem;
  color: #495057;
  font-weight: 500;
  text-align: center;
  flex: 1;
}

.versus {
  margin: 0 16px;
  color: #6c757d;
  font-weight: 600;
}

.matchDate {
  font-size: 0.9rem;
  color: #6c757d;
  text-align: center;
  padding: 8px;
  background-color: rgba(36, 128, 97, 0.1);
  border-radius: 8px;
}

.matchDate.invalid {
  color: #dc3545;
  background-color: rgba(220, 53, 69, 0.1);
}

.seeOddsButton {
  background-color: #248061 !important;
  color: white !important;
  border: none !important;
  padding: 12px 24px !important;
  border-radius: 8px !important;
  font-weight: 500 !important;
  transition: all 0.3s ease !important;
  width: 100% !important;
  margin-top: 12px !important;
  text-align: center !important;
}

.seeOddsButton:hover {
  background-color: #1a6047 !important;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.seeOddsButton:active {
  transform: translateY(0);
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  color: #248061;
  font-size: 1.1rem;
}

.blur {
  filter: blur(4px);
  pointer-events: none;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.matchCard {
  animation: fadeIn 0.3s ease-out;
}

/* Responsive Design */
@media (max-width: 768px) {
  .matchList {
    width: 100%;
    padding: 16px;
  }

  .matchCard {
    padding: 16px;
  }

  .teamName {
    font-size: 0.9rem;
  }

  .matchDate {
    font-size: 0.8rem;
  }

  .seeOddsButton {
    padding: 10px 20px !important;
  }
}

@media (max-width: 480px) {
  .matchList {
    width: 100%;
    padding: 12px;
  }

  .matchCard {
    padding: 12px;
  }

  .teamsContainer {
    flex-direction: column;
    gap: 8px;
  }

  .versus {
    margin: 8px 0;
  }
}
