.container {
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  padding: 24px;
  max-width: 1200px;
  margin: 24px auto;
  transition: all 0.3s ease;
}

.title {
  color: #248061;
  font-size: 1.8rem;
  font-weight: 600;
  margin-bottom: 24px;
  text-align: center;
  border-bottom: 2px solid #248061;
  padding-bottom: 8px;
}

.playersContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  margin-top: 20px;
}

.playerSection {
  background-color: #f8f9fa;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.playerSection h2 {
  color: #248061;
  font-size: 1.5rem;
  margin-bottom: 16px;
  text-align: center;
}

.ticket {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: transform 0.2s ease;
}

.ticket:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.ticketInfo {
  margin: 8px 0;
  color: #495057;
  font-size: 0.95rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ticketLabel {
  color: #6c757d;
  font-size: 0.9rem;
}

.ticketValue {
  font-weight: 600;
  color: #212529;
}

/* Status-specific styles */
.ticket-won {
  border-left: 4px solid #28a745;
}

.ticket-lost {
  border-left: 4px solid #dc3545;
}

.ticket-pending {
  border-left: 4px solid #ffc107;
}

.status {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.85rem;
  font-weight: 600;
  text-transform: uppercase;
}

.status-won {
  background-color: rgba(40, 167, 69, 0.1);
  color: #28a745;
}

.status-lost {
  background-color: rgba(220, 53, 69, 0.1);
  color: #dc3545;
}

.status-pending {
  background-color: rgba(255, 193, 7, 0.1);
  color: #ffc107;
}

.betButton {
  width: 100%;
  padding: 12px;
  background-color: #248061;
  color: white;
  border: none;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 16px;
}

.betButton:hover {
  background-color: #1a6047;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.betButton:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
  transform: none;
}

.alert {
  background-color: #dc3545;
  color: white;
  padding: 12px;
  border-radius: 8px;
  text-align: center;
  margin: 16px 0;
  font-weight: 500;
}

.opponentHistory {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: center;
  margin: 16px 0;
}

.historyItem {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 0.9rem;
  font-weight: 600;
}

.historyWin {
  background-color: #28a745;
}

.historyLost {
  background-color: #dc3545;
}

.historyTie {
  background-color: #ffc107;
}

.loading {
  text-align: center;
  padding: 40px;
  color: #6c757d;
}

.finalOdd {
  margin-top: 10px;
  padding: 8px;
  background-color: #f5f5f5;
  border-radius: 4px;
  text-align: center;
  font-weight: bold;
  color: #248061;
}

@media (max-width: 768px) {
  .container {
    margin: 16px;
    padding: 16px;
  }

  .title {
    font-size: 1.5rem;
  }

  .playersContainer {
    grid-template-columns: 1fr;
  }

  .playerSection {
    padding: 16px;
  }

  .playerSection h2 {
    font-size: 1.3rem;
  }
}
