.subNavbar {
  background-color: #248061;
  color: white;
  margin-bottom: 1rem !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  left: 30px;
  width: 80%;
  margin: 0 auto;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.navLinks {
  display: flex;
  gap: 2rem;
  align-items: center;
}

.navLink {
  color: white;
  text-decoration: none;
  font-weight: 500;
  font-size: 1rem;
  padding: 0.5rem 0.75rem;
  border-radius: 4px;
  transition: all 0.3s ease;
  cursor: pointer;
  position: relative;
}

.navLink:hover {
  background-color: rgba(255, 255, 255, 0.1);
  transform: translateY(-1px);
}

.navLink:active {
  transform: translateY(0);
}

.navLink::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 2px;
  background-color: white;
  transition: all 0.3s ease;
  transform: translateX(-50%);
}

.navLink:hover::after {
  width: 80%;
}

.navInfo {
  display: flex;
  align-items: center;
  gap: 2rem;
  font-size: 1rem;
}

.gameName {
  font-weight: 600;
  padding: 0.5rem 1rem;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  min-width: 120px;
  text-align: center;
}

.coinsInfo {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  transition: all 0.3s ease;
}

.coinsInfo:hover {
  background-color: rgba(255, 255, 255, 0.2);
  transform: translateY(-1px);
}

.coinIcon {
  width: 20px;
  height: 20px;
  filter: brightness(0) invert(1);
}

/* Animation for route changes */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.container > * {
  animation: fadeIn 0.3s ease-out;
}

/* Tablet styles */
@media (max-width: 768px) {
  .subNavbar {
    position: static;
    left: 0;
    width: 100%;
    margin: 1rem 0;
  }

  .container {
    flex-direction: column;
    gap: 1rem;
  }

  .navLinks {
    width: 100%;
    justify-content: center;
    gap: 1.5rem;
  }

  .navInfo {
    width: 100%;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 1rem;
  }

  .gameName {
    min-width: auto;
  }
}

/* Mobile styles */
@media (max-width: 480px) {
  .subNavbar {
    padding: 0.5rem 0;
    position: static;
    left: 0;
    width: 100%;
    margin: 1rem 0;
  }

  .container {
    padding: 0 0.5rem;
  }

  .navLinks {
    gap: 1rem;
  }

  .navLink {
    font-size: 0.9rem;
    padding: 0.4rem 0.6rem;
  }

  .navInfo {
    flex-direction: column;
    align-items: center;
    gap: 0.75rem;
  }

  .gameName,
  .coinsInfo {
    width: 100%;
    text-align: center;
    justify-content: center;
  }
}
