.BreadAndLanguageWrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 80%;
  margin: 15px auto;
  padding: 10px 0;
}

.depositWrapper {
  display: flex;
  align-items: center;
  gap: 5px;
}

.rightSection {
  display: flex;
  align-items: center;
  gap: 20px;
}

.moneyContainer {
  display: flex;
  align-items: center;
  background-color: #f8f9fa;
  border: 1px solid #248061;
  border-radius: 20px;
  padding: 8px 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  gap: 10px;
}

.moneyContainer:hover {
  background-color: rgba(36, 128, 97, 0.1);
  transform: translateY(-2px);
}

.moneyAmount {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.moneyValue {
  color: #248061;
  font-weight: 600;
  font-size: 1.1rem;
}

.depositText {
  font-size: 0.7rem;
  color: #248061;
  opacity: 0.8;
}

.depositIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background-color: #248061;
  color: white;
  border-radius: 50%;
  font-size: 1.2rem;
  font-weight: bold;
}

@media (max-width: 480px) {
  .BreadAndLanguageWrapper {
    display: none;
  }
}
