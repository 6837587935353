.qaContainer {
  background-color: #000;
  color: #fff;
  min-height: 100vh;
  padding: 20px;
  position: relative;
}

.languageSwitcherContainer {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1000;
  display: flex;
  align-items: center;
  gap: 15px;
}

.homeButton {
  padding: 8px 16px;
  background-color: #fff;
  color: #000;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-weight: bold;
  transition: transform 0.2s ease;
}

.homeButton:hover {
  transform: scale(1.1);
}

.content {
  max-width: 800px;
  margin: 60px auto 0;
  padding: 20px;
}

.content h1 {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 40px;
  text-align: center;
}

.qaSection {
  margin-bottom: 40px;
}

.qaSection h2 {
  font-size: 1.8rem;
  margin-bottom: 20px;
  color: #248061;
}

.qaItem {
  margin-bottom: 30px;
  background-color: rgba(255, 255, 255, 0.05);
  padding: 20px;
  border-radius: 10px;
}

.qaItem h3 {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.qaItem p {
  margin-bottom: 10px;
  line-height: 1.6;
}

.qaItem ul {
  list-style-type: none;
  padding-left: 20px;
}

.qaItem li {
  margin-bottom: 8px;
  position: relative;
}

/* .qaItem li:before {
  content: '•';
  color: #007bff;
  position: absolute;
  left: -15px;
}

*/

@media (max-width: 768px) {
  .content {
    padding: 10px;
  }

  .content h1 {
    font-size: 2rem;
  }

  .qaSection h2 {
    font-size: 1.5rem;
  }

  .qaItem {
    padding: 15px;
  }
}
