html,
.App {
  background-color: rgb(255, 255, 255);
}

.App {
  text-align: center;
  font-family: 'geo, open sans', sans-serif;
  overflow-x: hidden;
  min-height: 100dvh;
}

.main-container {
  margin-top: 2rem;
}

.nav-buttons {
  color: white !important;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.bet-info {
  display: flex;
  justify-content: space-evenly;
  align-items: baseline;
}

.cardd-text {
  font-size: 16px !important;
}

.carousel {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  position: relative;
}

.w-100 {
  width: 100% !important;
  height: 85vh;
  opacity: 0.4;
}

.carousel-caption {
  position: absolute;
  right: 5%;
  bottom: 1.25rem;
  left: 5%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.rightSideHome {
  background-color: rgb(22 124 90);
}

.rightSideHomeLogin {
  background-color: rgb(22 124 90);
}

.h3homeImg {
  margin-top: -20rem;
  font-weight: 800;
  font-size: 1.5rem;
  margin-bottom: 2rem;
}

.inputLogin {
  width: 30rem;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

.buttonLogin {
  width: 30rem;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  background-color: rgb(36, 128, 97);
  color: white;
  height: 3rem;
}

.accederH3 {
  font-weight: 600;
}

.link-register {
  color: black;
}
.link-register:hover {
  color: rgb(36, 128, 97);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.fotosLigas {
  height: 80vh;
  margin-top: 2rem;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* create league */
.form-create-league {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 1rem;
}

.form-create-league input {
  margin: 1.5rem;
  width: 13rem;
}

/* all leagues pages classes */
.red-text {
  color: red;
}
.join-league-button {
  border: none;
  background-color: white;
  border: 1px solid rgb(36, 128, 97);
  border-radius: 5px;
  margin: 8px 8px 18px 8px;
}

/* .join-league-button:hover {
	background-color: black;
	color: white;
} */

.info-league-container {
  border: 1px solid rgb(123, 127, 133);
  margin: 0.5rem;
  padding: 0;
  width: 22rem;
  background-color: white;
  text-align: center;
  min-height: 22rem;
}
.info-league-container > h4 {
  background-color: rgb(36, 128, 97);
  padding: 0.4rem;
  color: white;
}

.info-league-containerAbout {
  margin: 0.5rem;
  padding: 0;
  width: 22rem;
  background-color: white;
  text-align: center;
}
.info-league-containerAbout > h4 {
  background-color: rgb(36, 128, 97);
  padding: 0.4rem;
  color: white;
}

.fotosAbout {
  width: 22rem;
  height: 22rem;
  margin-bottom: 2rem;
}

.leagues-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

/* matches competitions page */

.matchOddsTitle {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

/* nav styles */

.nav-text {
  margin: 0 1rem 0 1rem;
}
.nav-text2 {
  display: flex;
  margin: 0 1rem 0 1rem;
  align-items: center;
}
.log-out {
  border: none;
  background-color: rgb(189, 61, 61);
  color: white;
  opacity: 0.7;
}

.log-out:hover {
  opacity: 1;
}

/* bet */
.bet-button {
  border: none !important;
  margin: 0.6rem;
  padding: 0.6rem;
  opacity: 1;
  background-color: rgb(36, 128, 97);
  width: 5rem;
  color: white;
}

.bet-input {
  margin: 8px;
}

.bet-button-two {
  width: 10rem !important;
  color: white;
  border-radius: 2px;
}

.bet-button:hover {
  opacity: 1;
}
.bet-button-details:hover {
  opacity: 1;
}

.bet-button-details {
  border: none !important;
  margin: 0.6rem;
  padding: 0.5rem;
  opacity: 0.7;
  background-color: rgb(36, 128, 97);
  width: 8rem;
}

.link-odds {
  text-decoration: none !important;
  color: rgb(36, 128, 97) !important;
  font-weight: bold !important;
}

.link-odds:hover {
  font-weight: bolder;
  font-size: larger;
}

.see-odds-button {
  background-color: rgb(36, 128, 97) !important;
  border: none !important;
}

.enter-button {
  background-color: transparent !important;
  border: 1px solid rgb(36, 128, 97) !important;
  width: 5rem;
  color: black !important;
}

.nav-text-right {
  text-decoration: none !important;
  list-style: none !important;
  font-weight: bold !important;
  margin: 0 1rem 0 1rem;
}

.nav-text-right2 {
  text-decoration: none !important;
  list-style: none !important;
  font-weight: bold !important;
  margin: 0 1rem 0 1rem;
}

/* 
home page */

.welcome-info {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  height: 74vh;
  justify-content: center;
}

.home-page-cont {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

#carouselExampleCaptions {
  margin-left: 4rem;
}
.welcome-info-text {
  text-align: left;
  margin: 1.5rem 0 1.5rem 0;
  height: 10rem;
}

.log-in-welcome {
  border: 0.5px solid white;
  border-radius: 4px;
  background-color: rgb(36, 128, 97);
  padding: 5px;
  color: white;
}

.principal-nav {
  background-color: rgb(36, 128, 97) !important;
}

.principal-nav:hover {
  background-color: rgb(36, 128, 97) !important;
}

.sub-navbar {
  background-color: rgb(36, 128, 97) !important;
  height: 2rem;
}

.ticket-pending {
  text-align: start;
  border: 2px solid rgb(236, 205, 28);
  padding: 8px;
  margin-top: 1.5rem;
  margin-right: 1.5rem;
  min-width: 17rem;
}
.ticket-lost {
  text-align: start;
  border: 2px solid rgb(236, 28, 28);
  padding: 8px;
  margin-top: 1.5rem;
  margin-right: 1.5rem;
  min-width: 17rem;
}
.ticket-won {
  text-align: start;
  border: 2px solid rgb(30, 136, 16);
  padding: 8px;
  margin-top: 1.5rem;
  margin-right: 1.5rem;
  min-width: 17rem;
}

.ticket-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.divisor {
  color: rgb(36, 128, 97);
}

.sign-up-welcome {
  border: 1px solid rgb(36, 128, 97);
  border-radius: 4px;
  background-color: white;
}

.joinLeagueSee {
  border: 1px solid rgb(36, 128, 97);
  margin-top: 2rem;
  width: 12rem;
}

.seeInscriptionLeague {
  margin-top: 1.5rem;
}

.seeLeaguesBack {
  text-decoration: none;
  color: black;
}

.home-card {
  width: 12rem !important;
  height: 14rem;
  border: none !important;
  margin: 16rem 6rem 3rem 40px;
}

.card-img-top {
  border-radius: 50px;
}

.cards-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-end;
  margin-top: 2rem !important;
}
.card-text {
  font-size: 0.8rem;
}

.first-col-home {
  position: relative;
  overflow: hidden;
  height: 92vh;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  background-color: #9ad1a1;
  margin-top: -1rem;
}

.first-col-home button {
  padding: 5px;
  position: relative;
  z-index: 2;
  background-color: rgb(36, 128, 97);
  border-radius: 30px;
  border: 2px solid rgb(36, 128, 97);
  width: 13rem !important;
  color: white;
  font-weight: 2000;
}

.infoLeagues {
  margin-top: 2rem;
  text-align: initial;
}

.info-leagues-title {
  text-align: center;
  font-weight: 800;
  margin-bottom: 5rem;
}

.info-text {
  margin-top: 1rem;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.second-col-home {
  margin-top: -1rem;
  position: relative;
  overflow: hidden;
  height: 92vh;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  background-color: #ebd64a;
}

.second-col-home button {
  padding: 5px;
  position: relative;
  z-index: 2;
  background-color: rgb(36, 128, 97);
  border-radius: 30px;
  border: 2px solid rgb(36, 128, 97);
  width: 13rem !important;
  color: white;
  font-weight: 2000;
}

.first-col {
  position: relative;
  overflow: hidden;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.first-col button {
  padding: 5px;
  position: relative;
  z-index: 2;
  background-color: rgb(36, 128, 97);
  border-radius: 30px;
  border: 2px solid rgb(36, 128, 97);
  width: 13rem !important;
  color: white;
  font-weight: 2000;
}

.first-col button:hover {
  background-color: rgb(36, 128, 97);
  color: white;
  border: none;
}
.second-col button:hover {
  background-color: rgb(36, 128, 97);
  color: white;
  border: none;
}
.second-row button:hover {
  background-color: rgb(36, 128, 97);
  color: white;
  border: none;
}

.first-col:before {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0.4;
  background-image: url('../src/pages/images/create-league.jpeg');
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
}

.second-col {
  position: relative;
  overflow: hidden;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.second-col button {
  padding: 5px;
  position: relative;
  z-index: 2;
  background-color: rgb(36, 128, 97);
  border-radius: 30px;
  border: 2px solid rgb(36, 128, 97);
  width: 13rem !important;
  color: white;
  font-weight: 2000;
}

/* You could use :after - it doesn't really matter */
.second-col:before {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0.4;
  background-image: url('../src/pages/images/join-league.jpeg');
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
}

.card-bet {
  border: none !important;
}

.second-row {
  position: relative;
  overflow: hidden;
  height: 30vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.second-row button {
  padding: 5px;
  position: relative;
  z-index: 2;
  background-color: rgb(36, 128, 97);
  border-radius: 30px;
  border: 2px solid rgb(36, 128, 97);
  width: 13rem !important;
  color: white;
  font-weight: 2000;
}

.second-row:before {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0.4;
  background-image: url('../src/pages/images/all-leagues.jpeg');
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
}

/* .accesCode {
  color: white;
} */

.join-league-button2 {
  border: none;
  background-color: white;
  border: 1px solid black;
  border-radius: 5px;
  margin: 8px 8px 18px 8px;
}

.finish-date {
  padding: 1rem;
}

.create-league-input {
  border: none;
  border-bottom: black 1px solid;
  text-align: center;
}
.create-league-input:focus {
  outline: 0px;
}

#potToWinners {
  margin: 1rem 0rem 2rem 0;
}

#potToWinners:focus {
  outline: 0px;
}

.card-size {
  width: 18rem;
}

.sportsCompetitions {
  display: flex;
  justify-content: center;
}

.imgSport {
  width: 5rem;
  height: 5rem;
  margin-right: 2rem;
}

.fotosLigas2 {
  width: 15rem;
}

.betQuicklyLink {
  text-decoration: none;
}

.underQuickly {
  text-decoration: underline;
}

.betQuickly {
  font-size: 2rem;
  color: rgb(0, 0, 0);
}

.showPassword {
  margin-left: -1.25rem;
}

.nav-textResponsive {
  display: flex;
  align-items: center;
}

.notificacionNav {
  width: 1rem;
}

.notification0 {
  display: none;
}

.hidePlayAgainTrue {
  display: none;
}

.hidePlayAgain {
  display: flex;
  justify-content: center;
}

.info-league-container2 {
  border: 1px solid rgb(123, 127, 133);
  margin: 0.5rem;
  padding: 0;
  background-color: white;
  text-align: center;
  position: absolute;
  margin-top: -18rem;
}
.info-league-container2 > h4 {
  background-color: rgb(36, 128, 97);
  padding: 0.4rem;
  color: white;
}

.info-league-containerHowIt {
  border: 1px solid rgb(123, 127, 133);
  margin: 0.5rem;
  padding: 0;
  background-color: white;
  text-align: center;
  /*  min-height: 22rem; */
}
.info-league-containerHowIt > h4 {
  background-color: rgb(36, 128, 97);
  padding: 0.4rem;
  color: white;
}

.info-league-containerHowIt2 {
  border: 1px solid rgb(123, 127, 133);
  margin: 0.5rem;
  padding: 0;
  background-color: white;
  text-align: center;
  height: auto;
}
.info-league-containerHowIt2 > h4 {
  background-color: rgb(36, 128, 97);
  padding: 0.4rem;
  color: white;
}

.divWhyAboutUs {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 4rem;
  flex-wrap: wrap;
  margin-top: 4rem;
}

.containerAboutUs {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.numberHowItWorks {
  width: 7vw;
}

.wantCoinsH3 {
  margin-bottom: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
}
.hiddenH {
  display: none;
}
.showNoMoney {
  display: flex;
  flex-direction: column;
  justify-content: center !important;
}

.showNoMoney > h4 {
  background-color: rgb(36, 128, 97);
  padding: 0.4rem;
  color: white;
}

.noMoneyContainer {
  display: flex;
  justify-content: center;
}

.buttonMoreLeagues {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.MoreLeaguesFutbol {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.buttonLeagues {
  margin-top: 1rem;
  margin-right: 1rem;
  width: 8rem;
  align-items: center;
  display: flex !important;
  justify-content: center;
}

.doubleChanceNoBet {
  display: flex;
  justify-content: space-around;
}

.divGoals {
  display: flex;
  justify-content: space-evenly;
  /*  margin-top: 1rem; */
}

.divOverGoals {
  display: flex;
  flex-direction: column;
}

/* .titleOdds {
  margin-top: 1rem;
} */

.subNavAbout {
  display: flex;
  background: #248061;
  height: 2rem;
  justify-content: space-around;
}

.textNavAbout {
  color: white !important;
  margin-top: auto !important;
  margin-bottom: auto;
}

.containerHowIt {
  display: flex;
  justify-content: center;
  height: 65vh;
  align-items: center;
}

.insideContainerHowIt {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.h3HowIt {
  margin-bottom: 1.5rem;
  margin-top: 1rem;
}

.h5HowIt {
  margin-top: 6vh;
}

.divAboutUs {
  display: flex;
  height: 48vh;
  align-items: center;
}

.imageAboutUs {
  width: 14rem;
  height: 10rem;
}

.playFor {
  display: flex;
  flex: 0 0 auto;
  width: 50%;
  flex-direction: column;
  align-items: center;
}

.formLeagueComplete {
  display: flex;
}

.form-league-complete {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 1rem;
}

.h6QuizEndLeague {
  margin-bottom: 1rem;
  margin-top: 1.5rem;
  font-weight: 600;
}

.textAreaQuiz {
  width: 50rem;
  height: 5rem;
}

.sendQuiz-button {
  width: auto;
  border: none !important;
  margin: 0.6rem;
  padding: 0.6rem;
  opacity: 1;
  background-color: rgb(36, 128, 97);
  color: white;
}

.formWithdraw {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.withdrawButton {
  padding: 5px;
  position: relative;
  z-index: 2;
  background-color: rgb(36, 128, 97);
  border-radius: 30px;
  border: 2px solid rgb(36, 128, 97);
  width: 13rem;
  color: white;
  margin-top: 2rem;
}

.h3Withdraw {
  margin-top: 2rem;
}

.cardFriend {
  margin-top: 2rem;
}

.goalscorerName {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.welcome-info3 {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
}

.fantasyHomeImg {
  width: 12rem;
}

.botonSingUp {
  width: 25rem;
  height: 3rem;
  background-color: rgb(36, 128, 97);
  color: white;
  margin-top: 1rem;
  border: none;
  border-radius: 0.5rem;
  font-size: 1.5rem;
}

.welcome-left {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.fantasyHomeImgMobile {
  display: none;
}

.link-to-login {
  display: none;
  color: black;
}

.link-to-login-pc {
  color: rgb(36, 128, 97);
}

.link-to-login-pc:hover {
  color: rgb(36, 128, 97);
}
.navigation {
  display: none !important;
}
.navigation:hover {
  display: block;
}

.depositPageDiv {
  display: flex;
  justify-content: center;
  height: 70vh;
  align-items: center;
  flex-direction: column;
}

.StripeElement {
  margin-bottom: 2rem;
  margin-top: 2rem;
}

.depositMoney input {
}

.__PrivateStripeElement {
  width: 29vw;
}

.matchOddsContainer {
  display: flex;
  flex-direction: column-reverse;
  padding: 0px 12px;
}

.matchOddsContainer:hover {
  background-color: #e1e4e3;
  border: 1px solid;
}

.match-details-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0px 20px;
  justify-content: center;
  margin-bottom: 5px;
}

.all-bets-container {
  padding-top: 25px;
  padding-bottom: 25px;
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-container {
  background-color: white;
  padding: 40px;
  border-radius: 5px;
  position: relative;
  min-width: 24rem;
  max-width: 80%;
  max-height: 80%;
  overflow: auto;
  z-index: 1001;
}

.modal-content {
  position: relative;
}

.close-button {
  position: absolute;
  top: 0px;
  right: 0px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.row {
  transition: filter 0.3s;
}

.row.blur {
  filter: blur(15px);
}

@media (max-width: 600px) {
  .home-page-cont {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    overflow-y: hidden;
  }
  .col-4 {
    flex: 0 0 auto;
    width: 80.33333333% !important;
  }

  .welcome-info {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    height: 36vh;
    justify-content: center;
  }

  .col-8 {
    flex: 0 0 auto;
    width: 80.33333333% !important;
  }

  #carouselExampleCaptions {
    background-color: red;
    margin-top: 3rem;
    margin-left: 0rem;
    height: 100vh;
    width: 100%;
  }

  .LoginPage {
    text-align: center;
    width: auto;
    margin: 0 auto;
    border-radius: 4px;
    background-color: transparent !important;
  }

  .leagueDetails {
    display: flex !important;
    flex-direction: column;
  }

  .playFor {
    width: 30rem !important;
  }

  .sub-navbar {
    display: flex;
    height: 4.5rem;
  }
  .subNavDet {
    width: 170px;
    display: flex !important;
    flex-direction: row !important;
  }

  .navInLeague2 {
    margin-right: 1rem !important;
  }

  .nav-text-right2 {
    display: hidden !important;
  }

  .navbar-nav {
    width: 38vw;
    display: flex;
    padding-left: 0;
    margin-bottom: 0;
    width: auto;
  }

  .clasificationLeague {
    width: 100% !important;
  }

  .graficaDetails {
    display: none !important;
  }

  .fotosLigas {
    height: 39vh !important;
  }

  .fotosLigas2 {
    height: 26vh;
    width: 25vw;
    margin-top: 2rem;
    margin-right: 1rem;
  }

  .competitionsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .nav-textResponsive {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
  }
  .nav-buttons-responsive {
    width: 62vw;
    display: flex !important;
    justify-content: center;
    margin-left: 3rem;
  }

  .card {
    width: auto;
  }
  .betQuickly {
    font-size: 1.2rem;
    margin-top: 1.2rem;
    color: rgb(0, 0, 0);
  }

  .insideContainerHowIt {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    height: auto;
  }

  .h5HowIt {
    margin-top: 2vh;
  }

  .numberHowItWorks {
    width: 15vw;
  }

  .subNavAbout {
    display: flex;
    background: #248061;
    justify-content: space-around;
    height: auto;
  }

  .divAboutUs {
    display: flex;
    height: auto;
  }

  .info-league-containerHowIt {
    border: 1px solid rgb(123, 127, 133);
    margin: 0.5rem;
    padding: 0;
    background-color: white;
    text-align: center;
    margin-top: -10vh;
  }

  .info-league-containerAbout {
    border: 1px solid rgb(123, 127, 133);
    margin: 0.5rem;
    padding: 0;
    background-color: white;
    text-align: center;
    width: auto;
  }

  .divWhyAboutUs {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 1rem;
    flex-wrap: wrap;
    margin-top: 1rem;
  }

  .formLeagueComplete {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .first-col-home {
    position: relative;
    overflow: hidden;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    background-color: #9ad1a1;
    width: 100% !important;
    padding-bottom: 2rem;
  }
  .second-col-home {
    position: relative;
    overflow: hidden;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    background-color: #ebd64a;
    width: 100% !important;
    padding-bottom: 2rem;
  }
  .info-leagues-title {
    text-align: center;
    font-weight: 800;
    margin-bottom: 1rem;
  }

  .welcome-info3 {
    display: flex;
    flex-direction: column;
    height: 80vh;
  }

  .inputLogin {
    width: 90vw;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
  }

  .buttonLogin {
    width: 90vw;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    background-color: rgb(36, 128, 97);
    color: white;
    height: 3rem;
  }

  .rightSideHomeLogin {
    display: none;
  }

  .lefSideHome {
    display: none;
  }

  .rightSideHome {
    width: 100% !important;
    height: 100vh;
  }
  .fantasyHomeImgMobile {
    display: block;
    width: 11rem;
    position: absolute;
    z-index: 300;
    margin-top: 3vh;
    margin-left: 30vw;
    opacity: 0.8;
  }
  .h3homeImg {
    margin-top: -18rem;
    font-weight: 800;
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }

  .link-to-login {
    display: block;
    color: white;
    z-index: 4000;
    position: absolute;
    margin-top: -24vh;
    margin-left: -6vw;
  }
  .w-100 {
    width: 100% !important;
    height: 65vh;
    opacity: 0.4;
  }

  .modal-container {
    min-width: 20rem;
  }
}

.noMoney {
  color: rgb(236, 28, 28);
}

.link-all-doubles {
  color: #198754;
}

.doubleWin {
  border-radius: 20px;
  color: white;
  background-color: green;
  margin-right: 5px;
  width: 50px;
}

.doubleLost {
  border-radius: 50%;
  background-color: red;
  color: white;
  margin-right: 5px;
  width: 50px;
}

.doublesOponent {
  display: flex;
}
