.container {
  min-height: 100vh;
  background-color: #f5f5f5;
}

.content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.title {
  font-size: 2rem;
  color: #333;
  margin-bottom: 2rem;
  text-align: center;
}

.filterContainer {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 2rem;
}

.filterButton {
  padding: 0.5rem 1.5rem;
  border: none;
  border-radius: 20px;
  background-color: #e0e0e0;
  cursor: pointer;
  transition: all 0.3s ease;
}

.filterButton.active {
  background-color: #007bff;
  color: white;
}

.messagesGrid {
  display: grid;
  gap: 1.5rem;
}

.messageCard {
  background-color: white;
  border-radius: 10px;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.messageCard:hover {
  transform: translateY(-5px);
}

.messageCard.answered {
  border-left: 4px solid #28a745;
}

.messageCard.pending {
  border-left: 4px solid #ffc107;
}

.messageHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.messageHeader h3 {
  margin: 0;
  color: #333;
  font-size: 1.2rem;
}

.date {
  color: #666;
  font-size: 0.9rem;
}

.messageBody {
  margin-bottom: 1rem;
}

.email {
  color: #666;
  margin-bottom: 0.5rem;
  font-style: italic;
}

.messageText {
  color: #333;
  line-height: 1.5;
}

.messageFooter {
  display: flex;
  justify-content: flex-end;
}

.statusButton {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.answeredButton {
  background-color: #28a745;
  color: white;
}

.answeredButton:hover {
  background-color: #218838;
}

.pendingButton {
  background-color: #ffc107;
  color: #000;
}

.pendingButton:hover {
  background-color: #e0a800;
}

@media (max-width: 768px) {
  .content {
    padding: 1rem;
  }

  .messagesGrid {
    grid-template-columns: 1fr;
  }

  .filterContainer {
    flex-wrap: wrap;
  }
}
