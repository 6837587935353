.container {
  width: 100%;
  margin: 0 auto;
  padding: 0rem 2rem;
  padding-left: 94px;
  min-height: calc(100dvh - 71px);
  background-color: #fff;
}

.main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1200px;
  margin: auto;
}

.contentWrapper {
  width: 100%;
  max-width: 1800px;
  margin: 0 auto;
  padding: 2rem 0;
}

.contentTitle h1 {
  color: #248061;
  font-size: 1.8rem;
  font-weight: 600;
  margin-bottom: 24px;
  text-align: center;
  border-bottom: 2px solid #248061;
  padding-bottom: 8px;
}

.cardContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  padding: 0 2rem;
  width: 100%;
}

/* Card styles */
.cardContainer > div {
  position: relative;
  height: 500px;
  border-radius: 20px;
  overflow: hidden;
  transition: all 0.3s ease;
  background-color: #fff;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.16);
}

.cardContainer > div:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 40px rgba(0, 0, 0, 0.25);
}

/* Card image styles */
.cardContainer img {
  width: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.cardContainer > div:hover img {
  transform: scale(1.05);
}

/* Card content styles */
.cardContainer > div > div {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(10px);
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  transition: all 0.3s ease;
}

.cardContainer > div:hover > div {
  background: rgba(255, 255, 255, 0.98);
}

.cardContainer h2 {
  font-size: 1.8rem;
  color: #000;
  font-weight: 500;
  margin: 0;
}

.cardContainer p {
  font-size: 1rem;
  color: #666;
  line-height: 1.5;
  margin: 0;
}

.cardContainer button {
  width: fit-content;
  padding: 0.8rem 2rem;
  font-size: 0.9rem;
  border: none;
  border-radius: 50px;
  background: #248061;
  color: #ffffff;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  margin-top: 1rem;
}

.cardContainer button:hover {
  transform: translateY(-2px);
  background: #248061;
}

/* Responsive adjustments */
@media (max-width: 1400px) {
  .cardContainer {
    gap: 1.5rem;
    padding: 0 1.5rem;
  }

  .cardContainer > div {
    height: 450px;
  }
}

@media (max-width: 1200px) {
  .cardContainer {
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
  }
}

@media (max-width: 968px) {
  .cardContainer {
    grid-template-columns: 1fr;
    max-width: 500px;
    margin: 0 auto;
    gap: 2rem;
    padding: 0 1rem;
  }

  .cardContainer > div {
    height: 400px;
  }

  .contentTitle h1 {
    text-align: center;
    margin-bottom: 2rem;
  }
}

@media (max-width: 480px) {
  .container {
    padding: 1rem;
  }

  .contentWrapper {
    padding: 0;
  }

  .cardContainer {
    gap: 1.5rem;
  }

  .cardContainer > div {
    height: 400px;
  }

  .cardContainer h2 {
    font-size: 1.5rem;
  }

  .cardContainer p {
    font-size: 0.9rem;
  }

  .cardContainer button {
    padding: 0.7rem 1.5rem;
    font-size: 0.8rem;
  }
}

/* For very small screens */
@media (max-width: 360px) {
  .cardContainer > div {
    height: 400px;
  }

  .cardContainer > div > div {
    padding: 1.25rem;
  }
}
