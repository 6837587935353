.MoreLeaguesFutbol {
  width: 10em;
}

.pageContainer {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0rem 2rem;
}

.sportsContainer {
  display: flex;
  justify-content: center;
  gap: 3rem;
  margin: 2rem 0;
}

.sportButton {
  background: none;
  border: none;
  cursor: pointer;
  transition: transform 0.3s ease;
  padding: 1rem;
  border-radius: 50%;
}

.sportButton:hover {
  transform: scale(1.1);
  background-color: rgba(0, 123, 255, 0.1);
}

.sportButton.active {
  background-color: rgba(0, 123, 255, 0.2);
  transform: scale(1.1);
}

.sportIcon {
  width: 80px;
  height: 80px;
  object-fit: contain;
}

.competitionsContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  gap: 2rem;
  margin: 2rem 0;
  padding: 1rem;
}

.competitionCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease;
}

.competitionCard:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.competitionImage {
  width: 150px;
  height: 350px;
  object-fit: contain;
  border-radius: 4px;
}

.moreLeaguesSection {
  margin: 2rem 0;
}

.moreLeaguesGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  gap: 1rem;
  margin-top: 1rem;
}

.leagueButton {
  padding: 0.8rem 1rem;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  font-size: 0.9rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-align: center;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 48px;
}

.leagueButton:hover {
  background-color: #0056b3;
}

.quickLinksSection {
  text-align: center;
  margin: 2rem 0;
  padding: 1rem;
  background-color: #f8f9fa;
  border-radius: 8px;
}

.quickLink {
  color: #007bff;
  text-decoration: none;
  font-size: 1.1rem;
  display: inline-block;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.quickLink:hover {
  background-color: rgba(0, 123, 255, 0.1);
}

.toggleButton {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 0.8rem 1.5rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
  margin: 1rem 0;
}

.toggleButton:hover {
  background-color: #218838;
}

@media (max-width: 768px) {
  .pageContainer {
    padding: 1rem;
  }

  .sportsContainer {
    gap: 1.5rem;
  }

  .sportIcon {
    width: 60px;
    height: 60px;
  }

  .competitionsContainer {
    grid-template-columns: repeat(auto-fit, minmax(90px, 1fr));
    gap: 1rem;
  }

  .competitionImage {
    width: 120px;
    height: 120px;
  }

  .moreLeaguesGrid {
    grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
  }
}
