/* ================================
   MatchDetailsUnified (unificado)
   ================================ */

/* Contenedor principal */
.matchDetails {
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  padding: 24px;
  transition: all 0.3s ease;
}

.matchDetailsContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

/* Grupos de apuestas */
.betGroup {
  background-color: #f8f9fa;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  transition: transform 0.2s ease;
  /* Animación */
  animation: fadeIn 0.3s ease-out;
}

.betGroup:hover {
  transform: translateY(-2px);
}

.betSubtypeTitle {
  font-size: 1.1rem;
  color: #248061;
  margin-bottom: 16px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  border-bottom: 2px solid #248061;
  padding-bottom: 8px;
}

/* Filas de apuestas (grid) */
.betRow {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 12px;
}

/* Tarjetas de cuotas/odds */
.matchOddsContainer {
  background-color: #ffffff;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  padding: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.matchOddsContainer:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 12px rgba(36, 128, 97, 0.15);
  border-color: #248061;
}

.matchOddsContainer:active {
  transform: translateY(-1px);
}

/* Texto de las cuotas/odds */
.odds {
  font-size: 1.4rem;
  font-weight: bold;
  color: #248061;
  margin-bottom: 8px;
}

/* Nombre de la apuesta */
.betName {
  font-size: 1rem;
  color: #495057;
  margin-bottom: 4px;
  font-weight: 500;
}

/* Handicap */
.handicap {
  font-size: 0.9rem;
  color: #248061;
  font-weight: 600;
  background-color: rgba(36, 128, 97, 0.1);
  padding: 2px 8px;
  border-radius: 12px;
  margin: 4px 0;
}

/* Header informativo (ej. "1st Half", etc.) */
.betHeader {
  font-size: 0.85rem;
  color: #6c757d;
  margin-top: 4px;
}

/* Botón de regresar o back */
.backToListButton {
  background-color: #248061 !important;
  border: none !important;
  padding: 10px 20px !important;
  margin-bottom: 16px !important;
  border-radius: 8px !important;
  font-weight: 500 !important;
  transition: all 0.3s ease !important;
  width: fit-content !important;
  margin: auto;
}

.backToListButton:hover {
  background-color: #1a6047 !important;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.backToListButton:active {
  transform: translateY(0);
}

/* Efecto blur (por ejemplo al cargar un modal encima) */
.blur {
  filter: blur(4px);
  pointer-events: none;
}

/* Estado de carga */
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  color: #248061;
}

/* Estado de error */
.error {
  color: #dc3545;
  text-align: center;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  margin: 20px 0;
}

/* Animación de aparición */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* ==============
   Responsive
   ============== */

/* Tablets y pantallas medianas */
@media (max-width: 768px) {
  .matchDetails {
    padding: 16px;
  }

  .betRow {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    gap: 8px;
  }

  .matchOddsContainer {
    padding: 12px;
  }

  .odds {
    font-size: 1.2rem;
  }

  .betName {
    font-size: 0.9rem;
  }

  .handicap {
    font-size: 0.8rem;
  }
}

/* Móviles */
@media (max-width: 480px) {
  .betRow {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  }

  .betSubtypeTitle {
    font-size: 1rem;
  }
}
