.profileContainer {
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  padding: 24px;
  max-width: 600px;
  margin: 24px auto;
  transition: all 0.3s ease;
}

.title {
  color: #248061;
  font-size: 1.8rem;
  font-weight: 600;
  margin-bottom: 24px;
  text-align: center;
  border-bottom: 2px solid #248061;
  padding-bottom: 8px;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.formGroup {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.formGroup label {
  color: #495057;
  font-size: 1rem;
  font-weight: 500;
}

.input {
  width: 100%;
  padding: 12px;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  font-size: 1rem;
  transition: all 0.3s ease;
  background-color: #f8f9fa;
}

.input:focus {
  outline: none;
  border-color: #248061;
  box-shadow: 0 0 0 2px rgba(36, 128, 97, 0.1);
  background-color: #ffffff;
}

.btn {
  background-color: #248061 !important;
  color: white !important;
  border: none !important;
  padding: 14px 28px !important;
  border-radius: 8px !important;
  font-weight: 500 !important;
  cursor: pointer !important;
  transition: all 0.3s ease !important;
  margin-top: 16px !important;
}

.btn:hover {
  background-color: #1a6047 !important;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.btn:active {
  transform: translateY(0);
}

.message {
  text-align: center;
  padding: 12px;
  border-radius: 8px;
  margin-top: 16px;
  animation: fadeIn 0.3s ease-out;
}

.message.success {
  background-color: rgba(36, 128, 97, 0.1);
  color: #248061;
}

.message.error {
  background-color: rgba(220, 53, 69, 0.1);
  color: #dc3545;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .profileContainer {
    margin: 16px;
    padding: 20px;
  }

  .title {
    font-size: 1.5rem;
  }

  .input,
  .btn {
    padding: 12px;
  }
}

@media (max-width: 480px) {
  .profileContainer {
    margin: 12px;
    padding: 16px;
  }

  .title {
    font-size: 1.3rem;
  }

  .input,
  .btn {
    padding: 10px;
  }

  .formGroup label {
    font-size: 0.9rem;
  }
}
