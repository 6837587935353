/* Global styles */
:global(body),
:global(html) {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
  color: #333;
  background-color: #fff;
}

/* Hero section with minimalist design */
.hero {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 100dvh;
  background: url('./assets/portada.avif') no-repeat center center/cover;
  position: relative;
}

.hero::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    to bottom,
    rgba(10, 10, 10, 0.3) 0%,
    rgba(10, 10, 10, 0.9) 100%
  );
  z-index: 1;
}

.heroContent {
  text-align: center;
  position: relative;
  z-index: 2;
  padding-bottom: 28dvh;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.heroContent p {
  color: #fff;
  font-size: clamp(1.1rem, 4vw, 1.8rem);
  font-weight: 300;
  margin: 0 auto;
  padding: 0 1vw;
  line-height: 1.4;
  letter-spacing: -0.02em;
}

/* Minimalist Scroll Indicator */
.scrollIndicator {
  position: absolute;
  left: 50%;
  bottom: 5dvh;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  cursor: pointer;
  z-index: 3;
  opacity: 0.7;
  transition: opacity 0.3s ease;
}

.scrollIndicator:hover {
  opacity: 1;
}

.scrollIndicator span {
  font-size: 0.875rem;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  margin-bottom: 1rem;
}

.scrollLine {
  width: 1px;
  height: 60px;
  background-color: #fff;
  transform-origin: top;
  animation: scrollPulse 2s infinite;
}

@keyframes scrollPulse {
  0% {
    transform: scaleY(0);
  }
  50% {
    transform: scaleY(1);
  }
  100% {
    transform: scaleY(0);
  }
}

/* CTA section with minimalist design */
.cta {
  display: flex;

  min-height: 100dvh;
}

.ctaContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5dvh 5vw;
  text-align: left;
}

.ctaContent h1 {
  font-size: clamp(2rem, 6vw, 2.7rem);
  margin-bottom: 1rem;
  font-weight: bold;
  max-width: 18rem;
  color: #248061;
}

.ctaContent h3 {
  margin-bottom: 20px;
  font-weight: bold;
  color: #495057;
}

.ctaForm {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
}

.ctaForm p {
  font-size: 0.9rem;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}

.ctaButton,
.loginButton {
  width: 100%;
  padding: 10px 20px;
  font-size: 1rem;
  margin-top: 10px;
  border: none;
  cursor: pointer;
  border-radius: 20px;
  font-weight: bold;
  transition:
    background-color 0.3s,
    color 0.3s;
}

.ctaButton {
  background-color: #fff;
  color: #248061;
  border: 2px solid #248061;
}

.loginButton {
  background-color: #248061;
  color: #fff;
  border: 2px solid #fff;
}

.login {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.login span {
  margin-right: 10px;
}

.ctaImage {
  flex: 1;
  display: flex;
  justify-content: center;
  /* align-items: center; */
}

.ctaImage img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

/* Card Container and Content Styles */
.container {
  background-color: #ffffff;
  /*   border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08); */
  padding: 24px;
  padding-left: 70px;
  max-width: 1200px;
  margin: auto;
  transition: all 0.3s ease;
}

.main {
  text-align: center;
  flex-grow: 1;
}

.contentWrapper {
  margin: 0 auto;
}

.contentTitle h1 {
  color: #248061;
  font-size: 1.8rem;
  font-weight: 600;
  margin-bottom: 24px;
  text-align: center;
  border-bottom: 2px solid #248061;
  padding-bottom: 8px;
}

.cardContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 24px;
  padding: 20px 0;
  justify-items: center;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.cardContainer {
  animation: fadeIn 0.3s ease-out;
}

/* Card styles */
.cardContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 24px;
  padding: 20px 0;
  justify-items: center;
}

.cardContainer > div {
  position: relative;
  height: 550px;
  width: 500px;
  border-radius: 20px;
  overflow: hidden;
  transition: all 0.3s ease;
  background-color: #fff;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.16);
}

.cardContainer > div:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 40px rgba(0, 0, 0, 0.25);
}

/* Card image styles */
.cardContainer img {
  width: 100%;
  transition: transform 0.3s ease;
  filter: brightness(1);
}

.cardContainer > div:hover img {
  transform: scale(1.05);
}

/* Card content styles */
.cardContainer > div > div {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(10px);
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  transition: all 0.3s ease;
}

.cardContainer > div:hover > div {
  background: rgba(255, 255, 255, 0.98);
}

.cardContainer h2 {
  font-size: 1.8rem;
  color: #000;
  font-weight: 500;
  margin: 0;
}

.cardContainer p {
  font-size: 1rem;
  color: #666;
  line-height: 1.5;
  margin: 0;
}

.cardContainer button {
  width: fit-content;
  padding: 0.8rem 2rem;
  font-size: 0.9rem;
  border: none;
  border-radius: 50px;
  background: #248061;
  color: #ffffff;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  margin-top: 1rem;
}

.cardContainer button:hover {
  transform: translateY(-2px);
  background: #248061;
}

/* Language Switcher */
.languageSwitcherContainer {
  position: fixed;
  top: 2rem;
  right: 2rem;
  z-index: 1000;
  display: flex;
  align-items: center;
  gap: 1.5rem;
  padding: 0.75rem 1rem;
  background-color: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  border-radius: 2rem;
}

.qaIcon {
  width: 24px;
  height: 24px;
  opacity: 0.7;
  transition: opacity 0.3s ease;
}

.qaIcon:hover {
  opacity: 1;
}

/* Background image responsive adjustments */
@media (max-width: 1200px) {
  .hero {
    background-image: url('./assets/portada.avif');
  }
}

@media (max-width: 768px) {
  .hero {
    background-image: url('./assets/portadaTablet.avif');
  }
  .cta {
    flex-direction: column;
  }
}

@media (max-width: 480px) {
  .hero {
    background-image: url('./assets/portadaMovil.avif');
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .container {
    margin: 16px;
    padding: 20px;
  }

  .contentTitle h1 {
    font-size: 1.5rem;
  }

  .cardContainer {
    grid-template-columns: 1fr;
    gap: 16px;
  }
}

@media (max-width: 480px) {
  .container {
    margin: 12px;
    padding: 16px;
  }

  .contentTitle h1 {
    font-size: 1.3rem;
  }

  .cardContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 24px;
    padding: 20px 0;
    justify-items: center;
  }

  .cardContainer > div {
    position: relative;
    height: 450px;
    width: 300px;
    border-radius: 20px;
    overflow: hidden;
    transition: all 0.3s ease;
    background-color: #fff;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.16);
  }
}

.ctaContent p {
  margin-top: 1rem;
  text-align: center;
  color: #495057;
}

.ctaContent a {
  color: #248061;
  text-decoration: none;
  transition: color 0.3s ease;
}

.ctaContent a:hover {
  color: #1a6047;
}

.ctaButton:hover {
  background-color: rgba(36, 128, 97, 0.1);
}

.loginButton:hover {
  background-color: #1a6047;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(36, 128, 97, 0.2);
}
