.entryPage {
  background-color: black;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 800px;
  padding: 0 20px;
}

.input {
  margin-bottom: 10px;
  padding: 10px;
  width: 50%;
  border: none;
  border-radius: 5px;
  font-size: 16px;
}

.button {
  padding: 10px 20px;
  background-color: white;
  color: black;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 16px;
}

.button:hover {
  background-color: #f0f0f0;
}

.error {
  color: red;
  margin-top: 10px;
  font-size: 14px;
}

@media (max-width: 768px) {
  .input {
    width: 70%;
  }
}

@media (max-width: 480px) {
  .input {
    width: 90%;
  }
}
