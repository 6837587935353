.depositContainer {
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  padding: 24px;
  max-width: 600px;
  margin: 24px auto;
  transition: all 0.3s ease;
}

.title {
  color: #248061;
  font-size: 1.8rem;
  font-weight: 600;
  margin-bottom: 24px;
  text-align: center;
  border-bottom: 2px solid #248061;
  padding-bottom: 8px;
}

.infoText {
  color: #6c757d;
  font-size: 0.9rem;
  text-align: center;
  margin-bottom: 16px;
  background-color: rgba(36, 128, 97, 0.1);
  padding: 12px;
  border-radius: 8px;
}

.card {
  background-color: #f8f9fa;
  border-radius: 10px;
  padding: 20px;
  margin-top: 20px;
}

.formGroup {
  margin-bottom: 20px;
}

.label {
  display: block;
  color: #495057;
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 8px;
}

.inputWrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.input {
  width: 100%;
  padding: 12px;
  padding-right: 30px;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  font-size: 1rem;
  transition: all 0.3s ease;
  background-color: #ffffff;
}

.input:focus {
  outline: none;
  border-color: #248061;
  box-shadow: 0 0 0 2px rgba(36, 128, 97, 0.1);
}

.currencySymbol {
  position: absolute;
  right: 12px;
  color: #6c757d;
  font-weight: 500;
}

.inputHint {
  font-size: 0.8rem;
  color: #6c757d;
  margin-top: 4px;
  text-align: right;
}

.message {
  text-align: center;
  padding: 12px;
  border-radius: 8px;
  margin-top: 16px;
  animation: fadeIn 0.3s ease-out;
}

.message.success {
  background-color: rgba(36, 128, 97, 0.1);
  color: #248061;
}

.message.error {
  background-color: rgba(220, 53, 69, 0.1);
  color: #dc3545;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .depositContainer {
    margin: 16px;
    padding: 20px;
  }

  .title {
    font-size: 1.5rem;
  }

  .input {
    padding: 10px;
  }
}

@media (max-width: 480px) {
  .depositContainer {
    margin: 12px;
    padding: 16px;
  }

  .title {
    font-size: 1.3rem;
  }

  .card {
    padding: 16px;
  }

  .input {
    font-size: 0.9rem;
  }
}

.switchButtons {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 2rem;
}

.activeButton,
.inactiveButton {
  padding: 0.5rem 2rem;
  border-radius: 20px;
  border: none;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  min-width: 120px;
}

.activeButton {
  background-color: #248061;
  color: white;
  box-shadow: 0 2px 4px rgba(36, 128, 97, 0.2);
}

.inactiveButton {
  background-color: #f8f9fa;
  color: #248061;
  border: 1px solid #248061;
}

.inactiveButton:hover {
  background-color: rgba(36, 128, 97, 0.1);
}

.promotionWrapper {
  margin: 15px 0;
  padding: 10px;
  background-color: rgba(36, 128, 97, 0.1);
  border-radius: 8px;
}

.promotionLabel {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #248061;
  font-weight: 500;
  cursor: pointer;
}

.promotionCheckbox {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.promotionInfo {
  margin-top: 8px;
  font-size: 0.9rem;
  color: #248061;
  padding-left: 28px;

  text-align: left;
  margin-top: 1rem;
}
