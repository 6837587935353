.breadcrumbs {
  padding: 12px 24px;
  background-color: #f8f9fa;
  border-radius: 8px;
  margin: 5px 24px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.breadcrumbLink {
  color: #248061;
  text-decoration: none;
  transition: color 0.3s ease;
}

.breadcrumbLink:hover {
  color: #1a6047;
  text-decoration: underline;
}

.breadcrumbLink:not(:last-child)::after {
  content: '/';
  margin: 0 8px;
  color: #6c757d;
}

.breadcrumbLast {
  color: #6c757d;
  font-weight: 500;
}

@media (max-width: 768px) {
  .breadcrumbs {
    padding: 8px 16px;
    margin: 12px 16px;
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .breadcrumbs {
    padding: 6px 12px;
    margin: 8px 12px;
    font-size: 0.85rem;
  }
}
