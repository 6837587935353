.container {
  max-width: 90%;
  margin: 2rem auto;
  padding: 1.5rem;
  /*   background: linear-gradient(145deg, #ffffff, #f0f0f0);
  border-radius: 20px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.1); */
  min-height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  padding-left: 94px;
}

.title {
  font-size: 2rem;
  color: #2c3e50;
  text-align: center;
  margin-bottom: 1.5rem;
  font-weight: 700;
}

.form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  padding: 0.5rem;
}

/* Make some inputs take full width */
.form > *:nth-last-child(-n + 3) {
  grid-column: 1 / -1;
}

.input {
  width: 100%;
  padding: 0.75rem;
  border: 2px solid #e0e0e0;
  border-radius: 12px;
  font-size: 0.9rem;
  transition: all 0.3s ease;
  background: white;
}

.input:focus {
  border-color: #248061;
  outline: none;
}

.select {
  width: 100%;
  padding: 0.75rem;
  border: 2px solid #e0e0e0;
  border-radius: 12px;
  font-size: 0.9rem;
  background: white;
  cursor: pointer;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23666' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 1.2em;
}

.btn {
  background: #248061;
  color: #ffffff;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 12px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 1px;
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
}

.btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px #248061;
}

.btn:active {
  transform: translateY(1px);
}

.errorMessage {
  color: #e74c3c;
  font-size: 0.9rem;
  text-align: center;
  padding: 0.75rem;
  margin: 0.75rem 0;
  background-color: #fdf0ef;
  border-left: 4px solid #e74c3c;
  border-radius: 8px;
}

.fileInput {
  position: relative;
  width: 100%;
}

.fileInput input[type='file'] {
  width: 100%;
  padding: 0.75rem;
  border: 2px dashed #e0e0e0;
  border-radius: 12px;
  cursor: pointer;
}

.previewImage {
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 12px;
  margin: 0.5rem auto;
  display: block;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.loginMessage {
  text-align: center;
  font-size: 1.1rem;
  color: #2c3e50;
  margin: 1.5rem 0;
}

.loginMessage a {
  color: #248061;
  text-decoration: none;
  font-weight: 600;
  transition: color 0.3s ease;
}

.loginMessage a:hover {
  color: #248061;
}

/* Responsive Design */
@media (max-width: 768px) {
  .form {
    grid-template-columns: 1fr;
  }

  .container {
    max-width: 95%;
    padding: 1rem;
    margin: 1rem auto;
  }

  .title {
    font-size: 1.75rem;
    margin-bottom: 1rem;
  }

  .input,
  .select {
    padding: 0.7rem;
  }
}

/* For very small screens */
@media (max-width: 480px) {
  .container {
    max-width: 100%;
    padding: 0.75rem;
    margin: 0.5rem 1.5rem;
  }

  .title {
    font-size: 1.5rem;
  }

  .input,
  .select,
  .btn {
    padding: 0.5rem;
    font-size: 0.9rem;
  }
}
