.form {
  margin-top: 20px;
  width: 100%;
}

.formGroup {
  margin-bottom: 1rem;
}

.formRow {
  display: flex;
  gap: 1rem;
}

.formRow .formGroup {
  flex: 1;
}

.label {
  display: block;
  font-size: 1rem;
  color: #555;
  margin-bottom: 0.5rem;
}

.input {
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: border-color 0.3s ease;
}

.input:focus {
  outline: none;
  border-color: #248061;
}

.cardElement {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f8f9fa;
  margin-bottom: 10px;
}

.cardDetailsRow {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.expiryElement,
.cvcElement {
  flex: 1;
  max-width: calc(50% - 5px);
}

.submitButton {
  width: 100%;
  padding: 12px;
  margin-top: 20px;
  font-size: 16px;
  color: #fff;
  background-color: #248061;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submitButton:hover {
  background-color: #248061;
}

.submitButton:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

@media (max-width: 480px) {
  .cardElement {
    padding: 8px;
  }

  .cardDetailsRow {
    flex-direction: column;
    gap: 10px;
  }

  .expiryElement,
  .cvcElement {
    max-width: 100%;
  }

  .submitButton {
    font-size: 14px;
    padding: 10px;
  }
}
