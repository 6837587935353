/* Navigation.module.css */
.principalNav {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 70px;
  background-color: #248061;
  padding: 50px 0;
  transition: width 0.3s ease;
  z-index: 1000;
  overflow-x: hidden;
}

.principalNav.expanded {
  width: 250px;
}

.navContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0;
}

.navItem {
  display: flex;
  align-items: center;
  padding: 15px;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
  white-space: nowrap;
}

.navItem:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.navIcon {
  width: 24px;
  height: 24px;
  margin-right: 15px;
}

.navText {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.expanded .navText {
  opacity: 1;
}

.subMenu {
  background-color: rgba(0, 0, 0, 0.1);
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.3s ease;
}

.subMenu.open {
  max-height: 200px;
}

.subMenuItem {
  text-align: left;
  padding: 12px 15px 12px 53px;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.subMenuItem:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.userSection {
  margin-top: auto;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding-top: 15px;
}

.userInfo {
  display: flex;
  align-items: center;
  padding: 15px;
  color: white;
}

.userAvatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 15px;
}

.mainContent {
  margin-left: 70px;
  transition: margin-left 0.3s ease;
  width: calc(100% - 70px);
}

.mainContent.shifted {
  margin-left: 250px;
  width: calc(100% - 250px);
}

/* Responsive */
@media (max-width: 768px) {
  .principalNav {
    width: 0;
    transform: translateX(-100%);
  }

  .principalNav.expanded {
    width: 250px;
    transform: translateX(0);
  }

  .mainContent {
    margin-left: 0;
    width: 100%;
  }

  .mainContent.shifted {
    margin-left: 0;
    width: 100%;
    opacity: 0.7;
  }

  .mobileToggle {
    position: fixed;
    top: 10px;
    left: 10px;
    z-index: 1001;
    background: #248061;
    border: none;
    color: white;
    padding: 10px;
    border-radius: 5px;
  }
}

/* Hamburger Menu Button */
.hamburgerButton {
  display: none;
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 1002;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 10px;
  transition: all 0.3s ease;
  color: white;
}

.hamburgerIcon {
  width: 30px;
  height: 20px;
  position: relative;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

.hamburgerIcon span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: white;
  border-radius: 3px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
}

.hamburgerIcon span:nth-child(1) {
  top: 0px;
}

.hamburgerIcon span:nth-child(2) {
  top: 8px;
}

.hamburgerIcon span:nth-child(3) {
  top: 16px;
}

/* Hamburger Animation */
.hamburgerIcon.open span:nth-child(1) {
  top: 8px;
  transform: rotate(135deg);
}

.hamburgerIcon.open span:nth-child(2) {
  opacity: 0;
  left: -60px;
}

.hamburgerIcon.open span:nth-child(3) {
  top: 8px;
  transform: rotate(-135deg);
}

/* Desktop styles (> 1024px) */
@media (min-width: 1025px) {
  .hamburgerButton {
    display: none;
  }

  .principalNav {
    width: 70px;
    transform: translateX(0);
  }

  .principalNav:hover {
    width: 250px;
  }

  .principalNav:hover .navText {
    opacity: 1;
  }

  .mainContent {
    margin-left: 70px;
    width: calc(100% - 70px);
  }

  .principalNav:hover + .mainContent {
    margin-left: 250px;
    width: calc(100% - 250px);
  }
}

/* Tablet Responsive (768px - 1024px) */
@media (max-width: 1024px) {
  .hamburgerButton {
    display: block;
  }

  .principalNav {
    width: 0;
    transform: translateX(-100%);
    box-shadow: none;
  }

  .principalNav.expanded {
    width: 280px;
    transform: translateX(0);
    box-shadow: 4px 0 10px rgba(0, 0, 0, 0.1);
  }

  .mainContent {
    margin-left: 0;
    width: 100%;
    padding-top: 70px;
  }

  .mainContent.shifted {
    margin-left: 0;
    width: 100%;
  }

  /* Overlay when menu is open */
  .mainContent.shifted::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }
}

/* Mobile Responsive (< 768px) */
@media (max-width: 767px) {
  .principalNav {
    padding: 62px 8px 10px 5px;
  }

  .hamburgerButton {
    top: 15px;
    left: 15px;
  }

  .principalNav.expanded {
    width: 100%;
  }

  .navItem {
    padding: 15px 20px;
  }

  .subMenuItem {
    padding: 12px 20px 12px 60px;
  }

  .mainContent {
    padding-top: 60px;
  }
}

/* Small Mobile Devices */
@media (max-width: 375px) {
  .principalNav {
    padding: 62px 8px 10px 5px;
  }

  .hamburgerButton {
    top: 10px;
    left: 10px;
  }

  .navItem {
    padding: 12px 15px;
  }

  .subMenuItem {
    padding: 10px 15px 10px 55px;
  }
}

.mobileHeader {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  background-color: #248061;
  padding: 0 15px;
  z-index: 1001;
  justify-content: space-between;
  align-items: center;
}

.leftSide {
  display: flex;
  align-items: center;
}

.rightSide {
  display: flex;
  align-items: center;
}

.hamburgerButton {
  margin-right: 0;
}

.mobileMoneyDisplay {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 6px 12px;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.mobileMoneyDisplay .moneyValue {
  color: white;
  font-weight: 600;
  font-size: 1rem;
}

.mobileMoneyDisplay .depositIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  background-color: white;
  color: #248061;
  border-radius: 50%;
  font-size: 1rem;
  font-weight: bold;
}

@media (max-width: 480px) {
  .mobileHeader {
    display: flex;
  }

  .hamburgerButton {
    margin-right: auto;
  }
}
