.App {
  min-height: 200vh;
}

.hero {
  height: 100dvh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background: url('../HomePage/assets/portada.avif') no-repeat center
    center/cover;
  position: relative;
}

.hero::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    to bottom,
    rgba(10, 10, 10, 0.3) 0%,
    rgba(10, 10, 10, 0.9) 100%
  );
  z-index: 1;
}

.heroContent {
  text-align: center;
  position: relative;
  z-index: 2;
  padding-bottom: 28dvh;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.heroContent p {
  color: #fff;
  font-size: clamp(1.1rem, 4vw, 1.8rem);
  font-weight: 300;
  margin: 0 auto;
  padding: 0 1vw;
  line-height: 1.4;
  letter-spacing: -0.02em;
}

.scrollIndicator {
  position: absolute;
  left: 50%;
  bottom: 5dvh;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  cursor: pointer;
  z-index: 3;
  opacity: 0.7;
  transition: opacity 0.3s ease;
}

.scrollIndicator:hover {
  opacity: 1;
}

.scrollIndicator span {
  font-size: 0.875rem;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  margin-bottom: 1rem;
}

.scrollLine {
  width: 1px;
  height: 60px;
  background-color: #fff;
  transform-origin: top;
  animation: scrollPulse 2s infinite;
}

@keyframes scrollPulse {
  0% {
    transform: scaleY(0);
  }
  50% {
    transform: scaleY(1);
  }
  100% {
    transform: scaleY(0);
  }
}

.cta {
  display: flex;
  min-height: 100dvh;
}

.ctaContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5dvh 5vw;
  text-align: left;
}

.ctaContent h1 {
  font-size: clamp(2rem, 6vw, 2.7rem);
  margin-bottom: 1rem;
  font-weight: bold;
  max-width: 18rem;
}

.ctaContent h3 {
  margin-bottom: 20px;
  font-weight: bold;
}

.ctaForm {
  display: flex;
  flex-direction: column;
}

.ctaInput {
  width: 100%;
  padding: 10px 20px;
  font-size: 1rem;
  margin-top: 10px;
  border: 2px solid #000;
  border-radius: 20px;
  font-weight: bold;
  background-color: #fff;
}

.submitButton {
  width: 100%;
  padding: 10px 20px;
  font-size: 1rem;
  margin-top: 10px;
  border: none;
  cursor: pointer;
  background-color: #000;
  color: #fff;
  border-radius: 20px;
  font-weight: bold;
}

.submitButton:hover {
  background-color: #333;
}

.submitButton:disabled {
  background-color: #666;
  cursor: not-allowed;
}

.message {
  margin-top: 15px;
  padding: 10px;
  border-radius: 20px;
  text-align: center;
}

.successMessage {
  background-color: #e8f5e9;
  color: #2e7d32;
  border: 1px solid #2e7d32;
}

.errorMessage {
  background-color: #ffebee;
  color: #c62828;
  border: 1px solid #c62828;
}

.links {
  margin-top: 20px;
  text-align: center;
}

.links a {
  color: #000;
}

.links a:hover {
  text-decoration: underline;
}

.loginLink {
  display: inline-block;
  margin-top: 10px;
  font-weight: bold;
}

.ctaImage {
  flex: 1;
  display: flex;
  justify-content: center;
}

.ctaImage img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.languageSwitcherContainer {
  position: fixed;
  top: 2rem;
  right: 2rem;
  z-index: 1000;
  display: flex;
  align-items: center;
  gap: 1.5rem;
  padding: 0.75rem 1rem;
  background-color: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  border-radius: 2rem;
}

.qaIcon {
  width: 24px;
  height: 24px;
  opacity: 0.7;
  transition: opacity 0.3s ease;
}

.qaIcon:hover {
  opacity: 1;
}

@media (max-width: 768px) {
  .hero {
    background-image: url('../HomePage/assets/portadaTablet.avif');
  }

  .cta {
    flex-direction: column;
  }

  .ctaContent {
    padding: 2rem;
  }

  .ctaImage {
    height: 50dvh;
  }
}

@media (max-width: 480px) {
  .hero {
    background-image: url('../HomePage/assets/portadaMovil.avif');
  }

  .ctaContent h1 {
    font-size: 1.8rem;
  }

  .languageSwitcherContainer {
    top: 1rem;
    right: 1rem;
  }
}
