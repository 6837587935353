.container {
  display: flex;
  flex-direction: row;
  min-height: 100dvh;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}

.loginForm {
  width: 1;
  max-width: 500px;
  padding: 5dvh 5vw;
  background-color: #fff;
}

.loginForm h1 {
  font-size: clamp(1.5rem, 5vw, 2rem);
  margin-bottom: 10px;
  font-weight: bold;
}

.loginForm h2 {
  font-size: clamp(1.2rem, 4vw, 1.5rem);
  margin-bottom: 20px;
  font-weight: bold;
}

.inputGroup {
  position: relative;
  margin-bottom: 20px;
}

.inputGroup input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
}

.passwordIcon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}

.loginButton {
  width: 100%;
  padding: 10px;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
}

.loginButton:hover {
  background-color: #333;
}

.loginForm p {
  margin-top: 20px;
  text-align: center;
}

.loginForm p a {
  color: #000;
  text-decoration: none;
}

.imageContainer {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imageContainer img {
  max-width: 100%;
  height: auto;
}

/* CTA section */
.cta {
  display: flex;
  min-height: 100dvh;
}

.ctaContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5dvh 5vw;
  text-align: left;
}

.ctaContent h1 {
  font-size: clamp(2rem, 6vw, 2.7rem);
  margin-bottom: 1rem;
  font-weight: bold;
  max-width: 18rem;
  color: #248061;
}

.ctaContent h3 {
  margin-bottom: 20px;
  font-weight: bold;
  color: #495057;
}

.ctaContent p {
  margin-top: 1rem;
  text-align: center;
  color: #495057;
}

.ctaContent a {
  color: #248061;
  text-decoration: none;
  transition: color 0.3s ease;
}

.ctaContent a:hover {
  color: #1a6047;
}

.ctaForm {
  display: flex;
  flex-direction: column;
}

.ctaForm p {
  font-size: 0.9rem;
  margin-bottom: 20px;
}

.ctaButton,
.loginButton {
  padding: 10px 20px;
  font-size: 1rem;
  margin-top: 10px;
  border: none;
  cursor: pointer;
}

.ctaButton {
  width: 100%;
  background-color: #fff;
  color: #248061;
  border-radius: 20px;
  border: 2px solid #248061;
  font-weight: bold;
  transition: all 0.3s ease;
}

.ctaButton:hover {
  background-color: rgba(36, 128, 97, 0.1);
}

.loginButton {
  width: 100%;
  background-color: #248061;
  color: #fff;
  border-radius: 20px;
  font-weight: bold;
  transition: all 0.3s ease;
}

.loginButton:hover {
  background-color: #1a6047;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(36, 128, 97, 0.2);
}

.login {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.login span {
  margin-right: 10px;
}

.ctaImage {
  flex: 1;
  display: flex;
  justify-content: center;
  /*  align-items: center; */
}

.ctaImage img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

/* Responsive design */
@media (min-width: 768px) {
  .container {
    flex-direction: row;
  }

  .loginForm,
  .imageContainer {
    flex: 1;
  }
}

@media (max-width: 767px) {
  .loginForm {
    padding: 20px;
  }

  .cta {
    flex-direction: column;
  }

  .ctaImage {
    height: 50dvh;
  }
}

/* Scroll Indicator */
.scrollIndicator {
  position: absolute;
  left: 5vw;
  bottom: 5dvh;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  cursor: pointer;
  z-index: 3;
}

.scrollIndicator span {
  font-size: 1rem;
  margin-bottom: 10px;
}

.scrollLine {
  width: 2px;
  height: 40px;
  background-color: #fff;
}

.arrowDown {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 10px solid #fff;
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

/* Hero section with minimalist design */
.hero {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 100dvh;
  background: url('../HomePage/assets/portada.avif') no-repeat center
    center/cover;
  position: relative;
}

.hero::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    to bottom,
    rgba(10, 10, 10, 0.3) 0%,
    rgba(10, 10, 10, 0.9) 100%
  );
  z-index: 1;
}

.heroContent {
  text-align: center;
  position: relative;
  z-index: 2;
  padding-bottom: 28dvh;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.heroContent p {
  color: #fff;
  font-size: clamp(1.1rem, 4vw, 1.8rem);
  font-weight: 300;
  margin: 0 auto;
  padding: 0 1vw;
  max-width: 800px;
  line-height: 1.4;
  letter-spacing: -0.02em;
}

/* Minimalist Scroll Indicator */
.scrollIndicator {
  position: absolute;
  left: 50%;
  bottom: 5dvh;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  cursor: pointer;
  z-index: 3;
  opacity: 0.7;
  transition: opacity 0.3s ease;
}

.scrollIndicator:hover {
  opacity: 1;
}

.scrollIndicator span {
  font-size: 0.875rem;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  margin-bottom: 1rem;
}

.scrollLine {
  width: 1px;
  height: 60px;
  background-color: #fff;
  transform-origin: top;
  animation: scrollPulse 2s infinite;
}

@keyframes scrollPulse {
  0% {
    transform: scaleY(0);
  }
  50% {
    transform: scaleY(1);
  }
  100% {
    transform: scaleY(0);
  }
}

.errorMessage {
  color: #dc3545;
  margin-top: 10px;
  padding: 8px;
  border-radius: 4px;
  background-color: rgba(220, 53, 69, 0.1);
}

/* Hero image responsive design */
@media (max-width: 1200px) {
  .hero {
    background: url('../HomePage/assets/portada.avif') no-repeat center
      center/cover;
  }
}

@media (max-width: 768px) {
  .hero {
    background: url('../HomePage/assets/portadaTablet.avif') no-repeat center
      center/cover;
  }
}

@media (max-width: 550px) {
  .hero {
    background: url('../HomePage/assets/portadaMovil.avif') no-repeat center
      center/cover;
  }
}

/* Language Switcher */
.languageSwitcherContainer {
  position: fixed;
  top: 2rem;
  right: 2rem;
  z-index: 1000;
  display: flex;
  align-items: center;
  gap: 1.5rem;
  padding: 0.75rem 1rem;
  background-color: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  border-radius: 2rem;
}

.qaIcon {
  width: 24px;
  height: 24px;
  opacity: 0.7;
  transition: opacity 0.3s ease;
}

.qaIcon:hover {
  opacity: 1;
}

/* Add media query for mobile responsiveness */
@media (max-width: 768px) {
  .languageSwitcherContainer {
    top: 10px;
    right: 10px;
  }
}

.passwordContainer {
  position: relative;
  width: 100%;
}

.passwordIcon {
  position: absolute;
  top: 55%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #666;
  display: flex;
  align-items: center;
  background: none;
  border: none;
  padding: 0;
}

.passwordIcon:hover {
  color: #333;
}

.ctaButton[type='password'],
.ctaButton[type='text'] {
  padding-right: 45px; /* Make room for the icon */
}
