.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 24px;
  /*   background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08); */
}

.title {
  color: #248061;
  font-size: 1.8rem;
  font-weight: 600;
  margin-bottom: 8px;
  text-align: center;
}

.subtitle {
  color: #495057;
  font-size: 1.2rem;
  margin-bottom: 24px;
  text-align: center;
}

.description {
  color: #6c757d;
  font-size: 1rem;
  margin-bottom: 16px;
  text-align: center;
  background-color: rgba(36, 128, 97, 0.1);
  padding: 12px;
  border-radius: 8px;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.inputGroup {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.input {
  padding: 12px;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.input:focus {
  outline: none;
  border-color: #248061;
  box-shadow: 0 0 0 2px rgba(36, 128, 97, 0.1);
}

.fileInput {
  background-color: #f8f9fa;
  padding: 12px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.fileInput:hover {
  background-color: #e9ecef;
}

.previewImage {
  width: 100%;
  max-width: 300px;
  height: auto;
  margin: 16px auto;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.btn {
  background-color: #248061;
  color: white;
  border: none;
  padding: 14px 28px;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 24px;
  width: 100%;
}

.btn:hover {
  background-color: #1a6047;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.btn:active {
  transform: translateY(0);
}

.errorMessage {
  color: #dc3545;
  background-color: rgba(220, 53, 69, 0.1);
  padding: 12px;
  border-radius: 8px;
  text-align: center;
  animation: fadeIn 0.3s ease-out;
  margin: 16px 0;
}

.loginPrompt {
  text-align: center;
  margin: 24px 0;
  padding: 16px;
  background-color: #f8f9fa;
  border-radius: 8px;
}

.loginPrompt a {
  color: #248061;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease;
}

.loginPrompt a:hover {
  color: #1a6047;
}

input,
textarea {
  width: 360px;
  padding: 10px;
  display: inline-block;
  margin: 10px;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .container {
    padding: 16px;
    margin: 16px;
  }

  .title {
    font-size: 1.5rem;
  }

  .subtitle {
    font-size: 1.1rem;
  }

  .btn {
    padding: 12px 24px;
  }
}

@media (max-width: 480px) {
  .container {
    padding: 12px;
    margin: 10px;
  }

  .title {
    font-size: 1.3rem;
  }

  .subtitle {
    font-size: 1rem;
  }

  .input,
  .btn {
    padding: 10px 20px;
  }
  .btn {
    margin-top: 0px;
  }

  input,
  textarea {
    width: 250px;
  }
}
